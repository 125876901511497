import type { ActionMenu } from '@monorepo/shared/componentsV2/menus/ActionMenu';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import * as Sentry from '@sentry/browser';
import { FieldResponse } from 'mapistry-shared';
import React, { useCallback, useEffect, useState } from 'react';
import { Bold } from '../components/EditLogPage/styled';
import { useLogFieldDelete } from './useLogFieldDelete';

interface UseLogFieldActionMenuParams {
  fieldType?: 'formula' | 'Log';
  logId?: string;
  organizationId?: string;
}

interface UseLogFieldCommonStateResponse {
  closeModal(): void;
  field?: FieldResponse;
  getMenu(
    logField: FieldResponse,
  ): React.ComponentProps<typeof ActionMenu>['options'];
  open: boolean;
  openModal(logField?: FieldResponse): void;
}

export const useLogFieldCommonState = ({
  fieldType = 'Log',
  logId,
  organizationId,
}: UseLogFieldActionMenuParams) => {
  const { confirm } = useModal();
  const { showUserFriendlyErrorToast, success } = useToast();
  const [isOpen, openModal, closeModal] = useOpenClose();
  const [field, setField] = useState<FieldResponse | undefined>(undefined);
  const [deleter] = useLogFieldDelete({ config: { throwOnError: true } });

  const handleDelete = useCallback(
    (logField: FieldResponse) => async () => {
      const isConfirmed = await confirm({
        title: `Delete ${fieldType} field`,
        description: (
          <>
            Are you sure you want to delete the <Bold>{logField.name}</Bold>{' '}
            {fieldType} field?
          </>
        ),
        cancelButtonText: `Keep ${fieldType} field`,
        confirmButtonText: `Delete ${fieldType} field`,
        danger: true,
      });
      if (isConfirmed) {
        try {
          if (!logId || !organizationId) {
            const errMsg = `Couldn't delete ${fieldType} field: This page doesn't have Log id or organization id`;
            Sentry.captureException(errMsg);
            throw new Error(errMsg);
          }
          await deleter({
            logFieldId: logField.id,
            logId,
            organizationId,
          });
          success(`${logField.name} has been deleted.`);
        } catch (err) {
          showUserFriendlyErrorToast(err, `Unable to delete ${logField.name}.`);
        }
      }
    },
    [
      confirm,
      deleter,
      showUserFriendlyErrorToast,
      fieldType,
      logId,
      organizationId,
      success,
    ],
  );
  const handleOpenModal = useCallback(
    (fieldToEdit?: FieldResponse) => {
      openModal();
      setField(fieldToEdit);
    },
    [openModal],
  );

  const getMenu = useCallback(
    (logField: FieldResponse) => [
      {
        displayName: 'Edit',
        onClick: () => handleOpenModal(logField),
      },
      {
        displayName: 'Delete',
        onClick: handleDelete(logField),
      },
    ],
    [handleDelete, handleOpenModal],
  );

  /**
   * Not putting the entire returned state into a `useState` hook would
   * cause the caller component to not re-render when an individual piece
   * of the returned state (field, open) was mutated. I think by returning
   * the value of a `useState` call instead of a memoized object containing
   * individual values from `useState` calls we are able to trigger the
   * necessary re-render in the caller
   */
  const [output, setOutput] = useState<UseLogFieldCommonStateResponse>({
    closeModal,
    field,
    getMenu,
    open: isOpen,
    openModal: handleOpenModal,
  });

  useEffect(() => {
    setOutput({
      closeModal,
      field,
      getMenu,
      open: isOpen,
      openModal: handleOpenModal,
    });
  }, [closeModal, field, getMenu, isOpen, handleOpenModal]);

  return output;
};
