import { ActionMenu } from '@monorepo/shared/componentsV2/menus/ActionMenu';
import { useToast } from '@monorepo/shared/contexts';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { FrequencyRequirementResponse } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useFrequencyRequirementDelete } from '../../hooks/useFrequencyRequirementDelete';

export function LogFrequencyRequirementActions({
  altText,
  frequencyRequirement,
  logId,
  onEditEntry,
  organizationId,
  projectId,
}: {
  altText: string;
  frequencyRequirement: FrequencyRequirementResponse;
  logId: string;
  onEditEntry: (f: FrequencyRequirementResponse) => void;
  organizationId: string;
  projectId: string;
}) {
  const { confirm } = useModal();
  const { success, error } = useToast();
  const [deleter] = useFrequencyRequirementDelete({
    config: { throwOnError: true },
  });

  const handleEdit = useCallback(
    () => onEditEntry(frequencyRequirement),
    [onEditEntry, frequencyRequirement],
  );

  const handleDelete = useCallback(async () => {
    try {
      const isConfirmed = await confirm({
        afterConfirmButtonText: 'Deleting...',
        cancelButtonText: 'Keep Frequency Requirement',
        confirmButtonText: 'Delete Frequency Requirement',
        danger: true,
        description:
          'Are you sure you want to delete the frequency requirement?',
        onConfirmAsync: () =>
          deleter({
            organizationId,
            logId,
            projectId,
            frequencyRequirementId: frequencyRequirement.id,
          }),
        title: 'Delete Frequency Requirement',
      });
      if (isConfirmed) {
        success(
          `Frequency Requirement has been deleted. The compliance calendar may take a few minutes to reflect these changes.`,
        );
      }
    } catch (err) {
      error('Error saving Frequency Requirement');
    }
  }, [
    confirm,
    deleter,
    error,
    frequencyRequirement.id,
    logId,
    organizationId,
    projectId,
    success,
  ]);

  const options = useMemo(
    () => [
      {
        displayName: 'Edit',
        onClick: handleEdit,
      },
      {
        displayName: 'Delete',
        onClick: handleDelete,
      },
    ],
    [handleEdit, handleDelete],
  );

  return <ActionMenu options={options} altText={altText} />;
}
