import { DEFAULT_AGGREGATION_METHOD } from '@monorepo/shared/componentsV2/aggregation/AggregationDefinitionsFieldArray/consts';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import AggregateIcon from '@svg/aggregate.svg';
import arrayMutators from 'final-form-arrays';
import {
  AggregationMethod,
  SaveWorkflowAggregationStepRequest,
  WorkflowStepOperationType,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { DeepPartial } from 'utility-types';
import {
  BaseEditStepModal,
  CommonEditStepModalProps,
} from '../BaseEditStepModal';
import { ALL_TIME_VALUE } from './AggregationInterval';
import { EditAggregationStepForm } from './EditAggregationStepForm';
import { FormValues } from './types';

export function EditAggregationStepModal({
  isNewStep,
  onSubmitStep,
  stepId,
  workflow,
  ...props
}: CommonEditStepModalProps<SaveWorkflowAggregationStepRequest>) {
  const submitStep = useCallback(
    async (data: FormValues) => {
      const step: SaveWorkflowAggregationStepRequest = {
        operationConfig: {
          ...data,
          aggregationDefinitions: data.aggregationDefinitions.map((ad) => ({
            ...ad,
            propertyId:
              ad.method === AggregationMethod.COUNT ? null : ad.propertyId,
          })),
          intervalPropertyId:
            data.intervalPropertyId === ALL_TIME_VALUE
              ? null
              : data.intervalPropertyId,
        },
        type: WorkflowStepOperationType.AGGREGATION,
      };
      await onSubmitStep(step);
    },
    [onSubmitStep],
  );

  const initialValues = useMemo<DeepPartial<FormValues>>(() => {
    if (isNewStep) {
      const emptyFormWithOneAggregation: DeepPartial<FormValues> = {
        aggregationDefinitions: [{ method: DEFAULT_AGGREGATION_METHOD }],
        groupedPropertyIds: [],
        intervalPropertyId: ALL_TIME_VALUE,
      };
      return emptyFormWithOneAggregation;
    }

    const step = workflow.steps.find((s) => s.id === stepId);
    if (!step || step.type !== WorkflowStepOperationType.AGGREGATION) {
      throw new Error(
        `EditAggregationStepModal was opened to edit not aggregation step. Type: ${step?.type}, step id: ${stepId}.`,
      );
    }
    // Defining this variable to get misspelling check. TS allows specify only known properties.
    const savedValues: DeepPartial<FormValues> = {
      aggregationDefinitions: step.operationConfig.aggregationDefinitions,
      groupedPropertyIds: step.operationConfig.groupedPropertyIds,
      intervalPropertyId: step.operationConfig.intervalPropertyId,
    };
    return savedValues;
  }, [isNewStep, stepId, workflow.steps]);

  return (
    <Form<FormValues, DeepPartial<FormValues>>
      onSubmit={submitStep}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      subscription={{ invalid: true, pristine: true, submitting: true }}
    >
      {(formProps) => (
        <BaseEditStepModal
          {...props}
          {...formProps}
          title="Aggregation"
          icon={<AggregateIcon />}
        >
          <ErrorBoundary>
            <EditAggregationStepForm stepId={stepId} workflow={workflow} />
          </ErrorBoundary>
        </BaseEditStepModal>
      )}
    </Form>
  );
}
