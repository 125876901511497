import { AggregationDefinitionFieldArray } from '@monorepo/shared/componentsV2/aggregation/AggregationDefinitionsFieldArray/AggregationDefinitionsFieldArray';
import { AggregationColumn, ColumnType } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { DeepPartial } from 'utility-types';
import { useSingleQueryStep } from '../../../contexts/SingleQueryStepContext';
import { FormValues } from './types';

export function AggregationDefinitionList() {
  const { availableColumns, isLastStep } = useSingleQueryStep();

  const getAllowedColumnOptions = useCallback(
    (formValues: DeepPartial<FormValues>) => {
      const selectedGroupedPropertyIds = formValues.groupBy || [];
      return availableColumns
        .filter((c) => !selectedGroupedPropertyIds.includes(c.columnName))
        .map((c) => ({
          label: c.columnLabel,
          value: c.columnName,
          isNumeric: c.columnType === ColumnType.NUMBER,
        }));
    },
    [availableColumns],
  );

  return (
    <AggregationDefinitionFieldArray<FormValues, 'columns', AggregationColumn>
      canEdit={isLastStep}
      childFieldNameForAlias="alias"
      childFieldNameForMethod="method"
      childFieldNameForColumn="columnName"
      fieldName="columns"
      getAllowedColumnOptions={getAllowedColumnOptions}
    />
  );
}
