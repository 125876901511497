import { AnyFieldValues } from '@monorepo/shared/componentsV2/aggregation/types';
import TrashIcon from '@svg/trash.svg';
import { AggregationMethod } from 'mapistry-shared';
import React, { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import styled from 'styled-components';
import { DeepPartial } from 'utility-types';
import { aggregationMethodLabels } from './consts';
import { isRequired } from '../../../utils/validators';
import { IconButton } from '../../Button/IconButton';
import { SelectField } from '../../fields/SelectField';
import { TextField } from '../../fields/TextField';
import { SelectOption } from '../../Select';
import { VisuallyHidden } from '../../VisuallyHidden';

const DeleteButton = styled(IconButton)<{ $labelIsShowing: boolean }>`
  min-width: 2rem;
  margin-bottom: ${({ $labelIsShowing }) =>
    $labelIsShowing
      ? '0.2rem'
      : '1.3rem'}; /* lines this up vertically with the input elements to its left */

  font-size: 0.75rem;
`;

export type AggregationColumnOption = SelectOption & { isNumeric: boolean };

function getSupportedMethods(selectedColumnOption?: AggregationColumnOption) {
  return selectedColumnOption && !selectedColumnOption.isNumeric
    ? [AggregationMethod.COUNT, AggregationMethod.COUNT_DISTINCT]
    : [
        AggregationMethod.SUM,
        AggregationMethod.AVERAGE,
        AggregationMethod.COUNT,
        AggregationMethod.COUNT_DISTINCT,
        AggregationMethod.MAX,
        AggregationMethod.MIN,
      ];
}

interface AggregationDefinitionProps<FormValues extends AnyFieldValues> {
  canEdit: boolean;
  formFieldName: string;
  childFieldNameForAlias: string;
  childFieldNameForMethod: string;
  childFieldNameForColumn: string;
  getAllowedColumnOptions: (
    formValues: DeepPartial<FormValues>,
  ) => AggregationColumnOption[];
  onDelete?: () => void;
  showLabel: boolean;
}

export function AggregationDefinition<FormValues extends AnyFieldValues>({
  canEdit,
  childFieldNameForAlias,
  childFieldNameForMethod,
  childFieldNameForColumn,
  formFieldName,
  getAllowedColumnOptions,
  onDelete,
  showLabel,
}: AggregationDefinitionProps<FormValues>) {
  const { change, getFieldState } = useForm();
  const { values: currentFormValues } = useFormState<DeepPartial<FormValues>>();
  const methodFieldState = getFieldState(
    `${formFieldName}.${childFieldNameForMethod}`,
  );
  const columnNameFieldState = getFieldState(
    `${formFieldName}.${childFieldNameForColumn}`,
  );
  const availableColumnOptions = useMemo(
    () => getAllowedColumnOptions(currentFormValues),
    [currentFormValues, getAllowedColumnOptions],
  );
  const selectedColumnOption = useMemo(
    () =>
      availableColumnOptions.find(
        (c) => c.value === columnNameFieldState?.value,
      ),
    [availableColumnOptions, columnNameFieldState?.value],
  );

  const columnOptions = useMemo(
    () =>
      availableColumnOptions
        .filter(
          (c) =>
            methodFieldState?.value === AggregationMethod.COUNT_DISTINCT ||
            c.isNumeric,
        )
        .sort((op1, op2) => op1.label.localeCompare(op2.label)),

    [availableColumnOptions, methodFieldState?.value],
  );

  const methodOptions = useMemo(
    () =>
      getSupportedMethods(selectedColumnOption).map((method) => ({
        label: aggregationMethodLabels[method],
        value: method,
      })),
    [selectedColumnOption],
  );

  // Clear column input when user selects/unselects COUNT method
  const isRowCount = methodFieldState?.value === AggregationMethod.COUNT;
  useEffect(() => {
    isRowCount && change(`${formFieldName}.${childFieldNameForColumn}`, '');
  }, [change, isRowCount, formFieldName, childFieldNameForColumn]);

  return (
    <>
      <TextField
        disabled={!canEdit}
        fullWidth
        isLabelHidden={!showLabel}
        label="Aggregation Name"
        name={`${formFieldName}.${childFieldNameForAlias}`}
        placeholder="Name your new column"
        required
        validate={isRequired}
      />
      <SelectField
        disabled={!canEdit}
        fullWidth
        isLabelHidden={!showLabel}
        label="Function"
        name={`${formFieldName}.${childFieldNameForMethod}`}
        options={methodOptions}
        required
        validate={isRequired}
      />
      {!isRowCount ? (
        <SelectField
          disabled={!canEdit}
          fullWidth
          isLabelHidden={!showLabel}
          label="Column"
          name={`${formFieldName}.${childFieldNameForColumn}`}
          options={columnOptions}
          placeholder="Column to aggregate"
          required
          validate={isRequired}
        />
      ) : (
        <div />
      )}
      {canEdit && onDelete ? (
        <DeleteButton onClick={onDelete} $labelIsShowing={showLabel}>
          <TrashIcon />
          <VisuallyHidden>Delete aggregation</VisuallyHidden>
        </DeleteButton>
      ) : (
        <div />
      )}
    </>
  );
}
