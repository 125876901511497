import { AggregationGroupByFieldArray } from '@monorepo/shared/componentsV2/aggregation/AggregationGroupByFieldArray/AggregationGroupByFieldArray';
import { DatasetPropertyType, DatasetResponse } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { DeepPartial } from 'utility-types';
import { FormValues } from './types';

const propertyTypesAvailableForGrouping = [
  DatasetPropertyType.BOOLEAN,
  DatasetPropertyType.TEXT,
  DatasetPropertyType.USER,
  DatasetPropertyType.UUID,
];

interface AggregationGroupBySectionProps {
  stepInputDataset: DatasetResponse;
}

export function AggregationGroupByList({
  stepInputDataset,
}: AggregationGroupBySectionProps) {
  const getAllowedGroupByColumnOptions = useCallback(
    (formValues: DeepPartial<FormValues>, fieldIndex?: number) => {
      const selectedGroupedPropertyIds = formValues.groupedPropertyIds || [];
      const selectedIdInTargetField =
        fieldIndex != null ? selectedGroupedPropertyIds[fieldIndex] : undefined;
      const aggregatedPropertyIds = (
        formValues.aggregationDefinitions || []
      ).map((ad) => ad?.propertyId);
      return stepInputDataset.schemaProperties
        .filter(
          (p) =>
            propertyTypesAvailableForGrouping.includes(p.type) &&
            !aggregatedPropertyIds.includes(p.id) &&
            // not allowed to group by the same property multiple times
            // unless it's selected in a dropdown we are returning available options for
            (selectedIdInTargetField === p.id ||
              !selectedGroupedPropertyIds.includes(p.id)),
        )
        .map((p) => ({
          label: p.fullName,
          value: p.id,
        }));
    },
    [stepInputDataset],
  );

  return (
    <AggregationGroupByFieldArray<FormValues>
      fieldName="groupedPropertyIds"
      getAllowedGroupByColumnOptions={getAllowedGroupByColumnOptions}
    />
  );
}
