import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import { Operator } from 'mapistry-shared';
import React from 'react';
import { ValueField } from './ValueField';

const operatorOptions: SelectableOption<Operator>[] = [
  { label: 'Contains', value: Operator.CONTAINS },
  { label: 'Does not contain', value: Operator.DOES_NOT_CONTAIN },
  { label: 'Is', value: Operator.EQUALS },
  { label: 'Is not', value: Operator.NOT_EQUALS },
  { label: 'Is empty', value: Operator.EMPTY },
  { label: 'Is not empty', value: Operator.NOT_EMPTY },
];

export function TextFilterCondition({ name }: { name: string }) {
  return (
    <>
      <SelectField
        isLabelHidden
        label="Operator"
        name={`${name}.operator`}
        options={operatorOptions}
        placeholder="Operator"
        required
        validate={isRequired}
      />
      <ValueField filterConditionName={name} />
    </>
  );
}
