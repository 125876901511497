import { SelectColumnOptionField } from '@monorepo/logs/src/components/QuerySteps/LimitQueryStep/SelectColumnOptionField';
import { useQuerySteps } from '@monorepo/logs/src/contexts/QueryStepsContext';
import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import { isRequired } from '@monorepo/shared/utils/validators';
import { ColumnType, GroupByFilter } from 'mapistry-shared';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

interface LimitFilterFieldsProps {
  hideLabels?: boolean;
  name: string;
}

export function LimitFilterFields({
  hideLabels = false,
  name,
}: LimitFilterFieldsProps) {
  const { originId, organizationId, projectId } = useQuerySteps();
  const { availableColumns } = useSingleQueryStep();

  return (
    <FieldArray<GroupByFilter, never> name={`${name}.groupByFilters`}>
      {({ fields: limitFilters }) => (
        <>
          {limitFilters.map((limitFilterName, idx) => {
            const limitFilter = limitFilters.value[idx];
            const column = availableColumns.find(
              (col) => col.columnName === limitFilter?.columnName,
            );
            if (!column) {
              throw new Error('Column not found');
            }

            const commonFieldProps = {
              fullWidth: true,
              isLabelHidden: hideLabels,
              key: limitFilterName,
              label: `${idx === 0 ? 'Where' : 'and'} ${column.columnLabel} is`,
              name: `${limitFilterName}.value`,
              required: true,
              validate: isRequired,
            };

            return column.columnType === ColumnType.FOREIGN_ID ? (
              <SelectColumnOptionField
                {...commonFieldProps}
                column={column}
                organizationId={organizationId}
                projectId={projectId}
                queryOriginId={originId}
              />
            ) : (
              <TextField {...commonFieldProps} placeholder="Enter a value" />
            );
          })}
        </>
      )}
    </FieldArray>
  );
}
