import { Loading } from '@monorepo/shared/components';
import { IconButton } from '@monorepo/shared/components/buttons';
import { useToast } from '@monorepo/shared/contexts';
import * as Sentry from '@sentry/browser';
import AddIcon from '@svg/add.svg';
import DocumentIcon from '@svg/document.svg';
import DownloadIcon from '@svg/download.svg';
import EditIcon from '@svg/edit.svg';
import SuccessIcon from '@svg/m-event-calendar-success.svg';
import WarningIcon from '@svg/m-event-calendar-warning.svg';
import { CalendarType, Id } from 'mapistry-shared';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '../../../../../../shared/hooks/permissions/useHasPermissions';
import apiCaller from '../../../../apiCaller';
import { useFormSubmission } from '../../../../hooks/forms/useFormSubmission';
import {
  FormSubmissionService,
  REQUEST_PDF_SUCCEEDED_MESSAGE,
} from '../../../../services/FormSubmissionService';
import { Card } from '../../../elements';
import { CannotEditFormTemplateModal } from '../CannotEditFormTemplateModal';

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
`;

const IconContainer = styled.span`
  display: flex;
`;

const SubmissionText = styled.span`
  padding-left: 0.5rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray123};
`;

export interface OneTimeCalendar {
  name: Id;
  summaryTitle: string;
  formTemplateSlug: string;
  formSubmissionSlug: string;
  formStencilSlug: string;
  type: CalendarType.ONE_TIME_FORM;
  canEditTemplate: boolean;
}

interface OneTimeFormCalendarCardProps {
  calendar: OneTimeCalendar;
  organizationId?: string;
  projectId?: Id;
}

export const OneTimeFormCalendarCard = ({
  calendar,
  organizationId,
  projectId,
}: OneTimeFormCalendarCardProps) => {
  const {
    formStencilSlug,
    formSubmissionSlug,
    formTemplateSlug,
    name: calendarId,
    summaryTitle,
    canEditTemplate,
  } = calendar;

  const submissionFetchParams = useMemo(
    () => ({
      config: { useErrorBoundary: false },
      calendarId,
      projectId,
      submissionSlug: formSubmissionSlug,
      templateSlug: formTemplateSlug,
    }),
    [calendarId, formSubmissionSlug, formTemplateSlug, projectId],
  );
  const {
    formSubmission: submission,
    isLoading: isSubmissionLoading,
    error: fetchSubmissionError,
  } = useFormSubmission(submissionFetchParams);

  const stencilFetchParams = useMemo(
    () => ({
      config: { useErrorBoundary: false },
      calendarId,
      projectId,
      submissionSlug: formStencilSlug,
      templateSlug: formTemplateSlug,
    }),
    [calendarId, formStencilSlug, formTemplateSlug, projectId],
  );
  const {
    formSubmission: stencil,
    isLoading: isStencilLoading,
    error: fetchStencilError,
  } = useFormSubmission(stencilFetchParams);

  const [hasAlreadyRequestedDownload, setHasAlreadyRequestedDownload] =
    useState(false);

  const { success } = useToast();
  const hasOrgUpdatePermissionOrSuperAdmin =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const navigate = useNavigate();
  const [
    isCannotEditFormTemplateModalOpen,
    setIsCannotEditFormTemplateModalOpen,
  ] = useState(false);

  const hasSubmission = !!submission?.id;
  const hasStencil = !!stencil;
  const isLoading = !projectId || isSubmissionLoading || isStencilLoading;

  useEffect(() => {
    if (isLoading) return;
    if (
      (fetchStencilError && fetchStencilError.status !== 404) ||
      (fetchStencilError && fetchStencilError.status !== 404)
    ) {
      throw new Error(
        `Couldn't fetch one-time form submission or its template`,
      );
    }
  }, [fetchStencilError, fetchSubmissionError, isLoading]);

  const handleOpenSubmission = useCallback(() => {
    const url = apiCaller.getFormSubmissionUrl(
      projectId,
      formTemplateSlug,
      formSubmissionSlug,
      calendarId,
    );
    window.location.href = url;
  }, [calendarId, formSubmissionSlug, formTemplateSlug, projectId]);

  const handleOpenStencil = useCallback(() => {
    const url = apiCaller.getFormStencilUrl(
      projectId,
      formTemplateSlug,
      formStencilSlug,
      calendarId,
    );
    window.location.href = url;
  }, [calendarId, formStencilSlug, formTemplateSlug, projectId]);

  const handleOpenFormTemplate = useCallback(() => {
    if (canEditTemplate) {
      navigate(`../../form-templates/${formTemplateSlug}/edit`);
    } else {
      setIsCannotEditFormTemplateModalOpen(true);
    }
  }, [
    canEditTemplate,
    formTemplateSlug,
    navigate,
    setIsCannotEditFormTemplateModalOpen,
  ]);

  const handleDownload = useCallback(async () => {
    if (!hasSubmission) return;
    try {
      setHasAlreadyRequestedDownload(true);
      await new FormSubmissionService().requestPdf(submission.id);
      success(REQUEST_PDF_SUCCEEDED_MESSAGE, { autoHideDuration: 10 * 1000 });
    } catch (e: unknown) {
      setHasAlreadyRequestedDownload(false);
      Sentry.captureException(
        'Unexpected error while trying to request an email download from a one time form calendar',
        {
          extra:
            e instanceof Error ? { errorMessage: e.message } : { error: e },
        },
      );
    }
  }, [hasSubmission, setHasAlreadyRequestedDownload, submission?.id, success]);

  const menuOptions = useMemo(() => {
    if (isLoading) return [];

    const options = [];
    if (hasSubmission) {
      options.push({
        label: 'Email PDF',
        icon: <DownloadIcon className="m-icon" />,
        onClick: handleDownload,
        disabled: hasAlreadyRequestedDownload,
      });
    } else {
      options.push({
        label: hasStencil ? 'Edit Site Template' : 'Create Site Template',
        icon: hasStencil ? (
          <EditIcon className="m-icon" />
        ) : (
          <AddIcon className="m-icon" />
        ),
        onClick: handleOpenStencil,
      });
    }
    if (hasOrgUpdatePermissionOrSuperAdmin) {
      options.push({
        label: 'Configure Form',
        icon: <DocumentIcon className="m-icon" />,
        onClick: handleOpenFormTemplate,
      });
    }
    return options;
  }, [
    handleDownload,
    handleOpenFormTemplate,
    handleOpenStencil,
    hasAlreadyRequestedDownload,
    hasOrgUpdatePermissionOrSuperAdmin,
    hasStencil,
    hasSubmission,
    isLoading,
  ]);

  const isComplete = useMemo(
    () => submission && submission.isComplete,
    [submission],
  );
  const icon = useMemo(() => {
    if (isComplete) {
      return <SuccessIcon className="m-icon m-event-calendar__success" />;
    }
    return <WarningIcon className="m-icon m-event-calendar__warning" />;
  }, [isComplete]);

  return (
    <>
      {/* @ts-expect-error - TODO: Fix this when Card component is typescriptified */}
      <Card menuOptions={menuOptions} title={summaryTitle}>
        <div className="card-icon__wrapper">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <DocumentIcon className="m-icon card-icon__icon" />
              <IconButton onClick={handleOpenSubmission}>
                <ButtonContent
                  className={`m-event-calendar__list__item m-event-calendar__type--${
                    isComplete ? 'success' : 'warning'
                  }`}
                >
                  <IconContainer className="m-event-calendar__type-icon">
                    {icon}
                  </IconContainer>
                  <SubmissionText>
                    {hasSubmission ? 'View/Edit' : 'Create'}
                  </SubmissionText>
                </ButtonContent>
              </IconButton>
            </>
          )}
        </div>
      </Card>
      {isCannotEditFormTemplateModalOpen && (
        <CannotEditFormTemplateModal
          open={isCannotEditFormTemplateModalOpen}
          onDone={() => setIsCannotEditFormTemplateModalOpen(false)}
        />
      )}
    </>
  );
};
