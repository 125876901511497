import React from 'react';
import { SectionHeader, Subtext } from './styled';

export function AggregateBySectionHeader() {
  return (
    <>
      <SectionHeader>Aggregate By</SectionHeader>
      <Subtext>
        All aggregations in this step will happen for the chosen time interval.
      </Subtext>
    </>
  );
}
