import { AggregateBySectionHeader } from '@monorepo/shared/componentsV2/aggregation/AggregateBySectionHeader';
import { GroupBySectionHeader } from '@monorepo/shared/componentsV2/aggregation/GroupBySectionHeader';
import { AggregationGroupByList } from '@monorepo/workflowDataSetup/src/components/EditWorkflowStepsPage/EditAggregationStepModal/AggregationGroupByList';
import { WorkflowResponse } from 'mapistry-shared';
import React from 'react';
import { findStepInputDataset } from '../stepUtils';
import { AggregationDefinitionList } from './AggregationDefinitionList';
import { AggregationInterval } from './AggregationInterval';

interface EditAggregationStepFormProps {
  workflow: WorkflowResponse;
  stepId?: string;
}

export function EditAggregationStepForm({
  stepId,
  workflow,
}: EditAggregationStepFormProps) {
  const stepInputDataset = findStepInputDataset(workflow, stepId);

  return (
    <>
      <AggregateBySectionHeader />
      <AggregationInterval stepInputDataset={stepInputDataset} />

      <AggregationDefinitionList stepInputDataset={stepInputDataset} />

      <GroupBySectionHeader />
      <AggregationGroupByList stepInputDataset={stepInputDataset} />
    </>
  );
}
