import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const StyledAccordion = styled(MuiAccordion)<{ $variant: AccordionVariant }>`
  &,
  &:first-child,
  &.Mui-expanded {
    margin: 0;
    border-color: ${({ theme }) => theme.colors.grayddd};
    border-style: solid;
    border-width: ${({ $variant }) =>
      $variant === 'contained' ? '1px' : '1px 0 1px 0'};
    border-radius: ${({ $variant }) =>
      $variant === 'contained' ? '8px' : '0'};
  }
`;

const StyledAccordionSummary = styled(MuiAccordionSummary)<{
  $color: AccordionColor;
  $variant: AccordionVariant;
}>`
  color: ${({ theme }) => theme.colors.gray333};
  background-color: ${({ $color, theme }) =>
    $color === 'secondary' ? theme.colors.white : theme.colors.blue04};
  border-radius: ${({ $variant }) => ($variant === 'contained' ? '8px' : '0')};

  &,
  &.Mui-expanded {
    /* keep the same height & margins when expanded */
    min-height: unset;

    & .MuiAccordionSummary-content {
      margin: 0.5rem 0;
      overflow: hidden;
    }

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.grayeee};
    }
  }
`;

type AccordionVariant = 'contained' | 'list';
type AccordionColor = 'primary' | 'secondary';

type AccordionProps = Pick<
  React.ComponentProps<typeof MuiAccordion>,
  'defaultExpanded' | 'disabled'
> & {
  className?: string;
  color?: AccordionColor;
  detailsClassName?: string;
  detailsContent: React.ReactNode;
  expandIcon?: React.ComponentProps<typeof MuiAccordionSummary>['expandIcon'];
  summaryContent: React.ReactNode;
  variant?: AccordionVariant;
};

export function Accordion({
  className,
  color = 'primary',
  defaultExpanded,
  detailsClassName,
  detailsContent,
  disabled,
  expandIcon,
  summaryContent,
  variant = 'list',
}: AccordionProps) {
  const attributePrefix = `accordion-${uuidv4()}`;
  return (
    <StyledAccordion
      className={className}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      elevation={0}
      $variant={variant}
    >
      <StyledAccordionSummary
        /* from MUI: For optimal accessibility we recommend setting id and aria-controls on the AccordionSummary.
        The Accordion will derive the necessary aria-labelledby and id for the content region of the accordion. */
        aria-controls={`${attributePrefix}-content`}
        $color={color}
        id={`${attributePrefix}-header`}
        expandIcon={expandIcon === undefined ? <ExpandMore /> : expandIcon}
        $variant={variant}
      >
        {summaryContent}
      </StyledAccordionSummary>
      <MuiAccordionDetails className={detailsClassName}>
        {detailsContent}
      </MuiAccordionDetails>
    </StyledAccordion>
  );
}
