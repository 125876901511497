import AddIcon from '@svg/add.svg';
import { AggregationMethod } from 'mapistry-shared';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { DeepPartial } from 'utility-types';
import { Button } from '../../Button';
import { AnyFieldValues } from '../types';
import {
  AggregationDefinition,
  AggregationColumnOption,
} from './AggregationDefinition';
import { DEFAULT_AGGREGATION_METHOD } from './consts';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 2rem;
  column-gap: 1rem;
  align-items: center;
  justify-items: center;
  width: 100%;
`;

const AddButton = styled(Button)`
  margin-bottom: 1.5rem;
`;

export function getDefaultAggregationMethod() {
  return AggregationMethod.SUM;
}

interface AggregationDefinitionFieldArrayProps<
  FormValues extends AnyFieldValues,
  FieldName extends Extract<keyof FormValues, string>,
  Definition extends FormValues[FieldName] extends Array<infer U> ? U : never,
> {
  canEdit?: boolean;
  childFieldNameForAlias: Extract<keyof Definition, string>;
  childFieldNameForMethod: Extract<keyof Definition, string>;
  childFieldNameForColumn: Extract<keyof Definition, string>;
  fieldName: FieldName;
  getAllowedColumnOptions: (
    formValues: DeepPartial<FormValues>,
  ) => AggregationColumnOption[];
}

export function AggregationDefinitionFieldArray<
  FormValues extends AnyFieldValues,
  FieldName extends Extract<keyof FormValues, string>,
  Definition extends FormValues[FieldName] extends Array<infer U> ? U : never,
>({
  canEdit = true,
  childFieldNameForAlias,
  childFieldNameForMethod,
  childFieldNameForColumn,
  fieldName,
  getAllowedColumnOptions,
}: AggregationDefinitionFieldArrayProps<FormValues, FieldName, Definition>) {
  return (
    <FieldArray name={fieldName}>
      {({ fields: formFields }) => (
        <>
          <Grid>
            {formFields.map((formFieldName, formFieldIndex) => (
              <AggregationDefinition
                canEdit={canEdit}
                childFieldNameForAlias={childFieldNameForAlias}
                childFieldNameForMethod={childFieldNameForMethod}
                childFieldNameForColumn={childFieldNameForColumn}
                formFieldName={formFieldName}
                getAllowedColumnOptions={getAllowedColumnOptions}
                key={formFieldName}
                onDelete={
                  canEdit && !!formFields.length && formFields.length > 1
                    ? () => formFields.remove(formFieldIndex)
                    : undefined
                }
                showLabel={formFieldIndex === 0}
              />
            ))}
          </Grid>
          {canEdit && (
            <AddButton
              color="primary"
              variant="text"
              onClick={() =>
                formFields.push({
                  [childFieldNameForMethod]: DEFAULT_AGGREGATION_METHOD,
                })
              }
            >
              <AddIcon />
              Add aggregation
            </AddButton>
          )}
        </>
      )}
    </FieldArray>
  );
}
