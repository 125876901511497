import { Analytics } from '@monorepo/analytics/src/components/Analytics';
import { FormTemplates } from '@monorepo/formTemplates/src/components/FormTemplates';
import { Inspections } from '@monorepo/inspections/src/components/Inspections';
import { LogDataSetup } from '@monorepo/logDataSetup/src/components/LogDataSetup';
import { Logs } from '@monorepo/logs/src/components/Logs';
import { Records } from '@monorepo/records/src/components/Records';
import { ResourceDataSetup } from '@monorepo/resourceDataSetup/src/components/ResourceDataSetup';
import { DefaultDocumentTitle } from '@monorepo/shared/componentsV2/DocumentTitle';
import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import { useHasAnalyticsAccess } from '@monorepo/shared/hooks/permissions/useHasAnalyticsPermissions';
import { SiteDetails } from '@monorepo/siteDetails/src/components/SiteDetails';
import { Tasks } from '@monorepo/tasks/src/components/Tasks';
import { Users } from '@monorepo/users/src/components/Users';
import { WorkflowDataSetup } from '@monorepo/workflowDataSetup/src/components/WorkflowDataSetup';
import { useFeatureFlags } from 'mapistry-shared/api';
import React from 'react';
import { Navigate, Route } from 'react-router-dom-v5-compat';
import { BackboneAppUrlsToRedirectToVortexUrls } from './BackboneAppUrlsToRedirectToVortexUrls';
import { DataSetup } from './DataSetup';
import { Organization } from './Organization';
import { Project } from './Project';
import { ProjectLayout } from './ProjectLayout';

export function Router() {
  const { areWorkflowsEnabled, isData2Enabled } = useFeatureFlags();
  const hasAnalyticsAccess = useHasAnalyticsAccess();

  return (
    <>
      <DefaultDocumentTitle />
      <RoutesWith404>
        <Route path="/" element={<Navigate replace to="vortex" />} />
        <Route path="vortex">
          <Route index element={<Navigate replace to="organizations" />} />
          <Route path="organizations">
            <Route index element={<Organization />} />
            <Route path=":organizationId">
              <Route index element={<Navigate replace to="sites" />} />
              <Route path="sites">
                <Route index element={<Project />} />
                <Route path=":projectId" element={<ProjectLayout />}>
                  <Route index element={<Navigate replace to="tasks" />} />
                  <Route path="tasks/*" element={<Tasks />} />
                  <Route path="inspections/*" element={<Inspections />} />
                  <Route path="logs/*" element={<Logs />} />
                  <Route path="records/*" element={<Records />} />
                  <Route path="site-details/*" element={<SiteDetails />} />
                  <Route path="users/*" element={<Users />} />
                  <Route path="form-templates/*" element={<FormTemplates />} />
                </Route>
              </Route>
              {isData2Enabled && (
                <Route path="data-setup" element={<DataSetup />}>
                  <Route index element={<Navigate replace to="logs" />} />
                  <Route path="logs/*" element={<LogDataSetup />} />
                  <Route
                    path="resource-types/*"
                    element={<ResourceDataSetup />}
                  />
                  {areWorkflowsEnabled && (
                    <Route path="workflows/*" element={<WorkflowDataSetup />} />
                  )}
                </Route>
              )}
            </Route>
          </Route>
          {hasAnalyticsAccess && (
            <Route path="analytics/*" element={<Analytics />} />
          )}
        </Route>
        {BackboneAppUrlsToRedirectToVortexUrls()}
      </RoutesWith404>
    </>
  );
}
