import { Button } from '@monorepo/shared/componentsV2/Button';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { WorkflowResponse } from 'mapistry-shared';
import React from 'react';

export type CommonEditStepModalProps<SpecificStepRequest> = {
  isOpen: boolean;
  onClose: () => void;
  onSubmitStep: (stepConfig: SpecificStepRequest) => Promise<void>;
  stepId?: string;
  isNewStep: boolean;
  workflow: WorkflowResponse;
};

interface BaseEditStepModalProps {
  children: React.ReactNode;
  handleSubmit: () => void;
  icon: React.ReactElement;
  invalid: boolean;
  isOpen: boolean;
  onClose: () => void;
  pristine: boolean;
  submitting: boolean;
  title: string;
}

export function BaseEditStepModal({
  children,
  handleSubmit,
  icon,
  invalid,
  isOpen,
  onClose,
  pristine,
  submitting,
  title,
}: BaseEditStepModalProps) {
  return (
    <BaseModal
      title={title}
      icon={icon}
      open={isOpen}
      showCloseButton
      onClose={onClose}
      maxWidth="lg"
      buttons={
        <>
          <Button color="primary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={pristine || invalid || submitting}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            {submitting ? 'Saving...' : 'Save'}
          </Button>
        </>
      }
    >
      {children}
    </BaseModal>
  );
}
