import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import TrashIcon from '@svg/trash.svg';
import { ColumnType, SingleFilter } from 'mapistry-shared';
import React, { useCallback, useEffect, useRef } from 'react';
import { useField, useForm } from 'react-final-form';
import styled from 'styled-components';
import { FilterConditionFactory } from './FilterConditionFactory';

const DeleteButton = styled(IconButton)`
  min-width: 2rem;
  margin-bottom: 0.2rem; /* lines this up vertically with the input elements to its left */
  font-size: 0.75rem;
`;

export function FilterCondition({
  columnOptions,
  conjunctionComponent,
  name,
  onDelete,
}: {
  columnOptions: SelectableOption[];
  conjunctionComponent: React.ReactNode;
  name: string;
  onDelete?: () => void;
}) {
  const { availableColumns } = useSingleQueryStep();
  const {
    input: { value: columnName },
  } = useField<SingleFilter['columnName']>(`${name}.columnName`);

  const columnType = availableColumns.find(
    (c) => c.columnName === columnName,
  )?.columnType;
  const previousColumnTypeRef = useRef<ColumnType | undefined>();
  useEffect(() => {
    previousColumnTypeRef.current = columnType;
  });
  const previousColumnType = previousColumnTypeRef.current;
  const { change } = useForm();
  const resetValue = useCallback(
    () => change(`${name}.value`, null),
    [change, name],
  );
  useEffect(() => {
    if (previousColumnType && columnType !== previousColumnType) {
      // reset filter value when column type changes
      resetValue();
    }
  }, [name, resetValue, previousColumnType, columnType]);

  return (
    <>
      {conjunctionComponent}
      <SelectField
        isLabelHidden
        label="Column"
        name={`${name}.columnName`}
        options={columnOptions}
        placeholder="Column"
        required
        validate={isRequired}
      />
      <FilterConditionFactory columnType={columnType} name={name} />
      {onDelete ? (
        <DeleteButton onClick={onDelete}>
          <VisuallyHidden>Delete condition</VisuallyHidden>
          <TrashIcon />
        </DeleteButton>
      ) : (
        <>
          {/* put an empty div here to keep the number of columns in the grid consistent */}
          <div />
        </>
      )}
    </>
  );
}
