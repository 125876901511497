import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { startOfDay } from 'date-fns';
import { NullableDate } from 'mapistry-shared';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  getCommonPickerProps,
  muiPickerCss,
  MuiPickerPropsToOmitOnWrapper,
  PickerHelperText,
  PickerInputLabel,
  useOnChangeWrapper,
} from './shared';

type DatePickerProps = Omit<
  KeyboardDatePickerProps,
  MuiPickerPropsToOmitOnWrapper | 'format' | 'hiddenLabel'
> & {
  errorText?: string;
  isLabelHidden?: boolean;
  name: string; // add name for unique ID
  // this better matches the signature for html input & final form field's onChange
  onChange: (event: { target: { value: NullableDate } }) => void;
  tooltipText?: string;
};

const StyledDatePicker = styled(KeyboardDatePicker)`
  ${muiPickerCss}
` as typeof KeyboardDatePicker;

export const DATE_PICKER_DISPLAY_FORMAT = 'MM/dd/yyyy';

export function DatePicker({
  className,
  error,
  errorText,
  isLabelHidden = false,
  helperText,
  label,
  tooltipText,
  name,
  onChange: onChangeProp,
  required,
  value,
  ...rest
}: DatePickerProps) {
  const [idRandomify] = useState(uuidv4);
  const pickerInputElId = `${name}-id-${idRandomify}`;
  const onChange = useOnChangeWrapper(onChangeProp, (date) =>
    // when using the picker (instead of typing directly the date with the keyboard), the MUI picker
    //  gives you a date object back with the selected date & the current browser time
    // to better indicate that the user is ONLY picking a date, set this returned date to midnight
    date ? startOfDay(date) : date,
  );
  const labelComponent = (
    <PickerInputLabel
      required={required}
      error={error}
      htmlFor={pickerInputElId}
      label={label}
      tooltipText={tooltipText}
    />
  );

  return (
    <div className={className}>
      {isLabelHidden ? (
        <VisuallyHidden>{labelComponent}</VisuallyHidden>
      ) : (
        labelComponent
      )}
      <StyledDatePicker
        required={required}
        error={error}
        onChange={onChange}
        value={value || null} // an unselected date should have value === null
        format={DATE_PICKER_DISPLAY_FORMAT}
        initialFocusedDate={new Date()}
        {...getCommonPickerProps(pickerInputElId)}
        {...rest}
      />
      <PickerHelperText
        error={error}
        errorText={errorText}
        helperText={helperText}
      />
    </div>
  );
}
