import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import {
  compareDatetimeOriginTransformations,
  DatasetPropertyResponse,
  DatasetPropertyType,
  DatasetResponse,
  DatetimeOriginTransformation,
} from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const StyledSelectField = styled(SelectField)`
  width: 15rem;
`;

export const ALL_TIME_VALUE = '__ALL_TIME_VALUE__';

type DatasetPropertyWithDateTransformation = Omit<
  DatasetPropertyResponse,
  'dateTransformation'
> & {
  dateTransformation: NonNullable<
    DatasetPropertyResponse['dateTransformation']
  >;
};

const allowedTransformations = [
  DatetimeOriginTransformation.DAY,
  DatetimeOriginTransformation.WEEK,
  DatetimeOriginTransformation.MONTH,
  DatetimeOriginTransformation.QUARTER,
  DatetimeOriginTransformation.YEAR,
];

interface AggregationIntervalProps {
  stepInputDataset: DatasetResponse;
}

export function AggregationInterval({
  stepInputDataset,
}: AggregationIntervalProps) {
  const availableIntervalOptions = useMemo(() => {
    const allTime = {
      label: 'All time',
      value: ALL_TIME_VALUE,
    };

    const properties = stepInputDataset.schemaProperties;
    const timeSeriesProperty = properties.find((p) => p.isTimeSeriesDate);
    // if there's no time series date property on which to do an interval aggregation, the only option is "All time"
    if (!timeSeriesProperty) return [allTime];

    // We will pick child date ranges of time series property as options for aggregation interval,
    // unless time series property is a child date range itself, in this case we need to pick its siblings.
    const timeSeriesDateRangesParentId =
      timeSeriesProperty.type === DatasetPropertyType.DATE_RANGE &&
      timeSeriesProperty.parentPropertyId
        ? timeSeriesProperty.parentPropertyId
        : timeSeriesProperty.id;
    const timeSeriesDateRanges = properties
      .filter(
        (p): p is DatasetPropertyWithDateTransformation =>
          p.parentPropertyId === timeSeriesDateRangesParentId &&
          p.type === DatasetPropertyType.DATE_RANGE &&
          !!p.dateTransformation &&
          allowedTransformations.includes(p.dateTransformation),
      )
      .sort(
        (p1, p2) =>
          // Sorting from the biggest interval, e.g. year, to the smallest, e.g. minute
          -1 *
          compareDatetimeOriginTransformations(
            p1.dateTransformation,
            p2.dateTransformation,
          ),
      )
      .map((p) => ({
        label: p.fullName,
        value: p.id,
      }));

    return [allTime, ...timeSeriesDateRanges];
  }, [stepInputDataset]);

  return (
    <StyledSelectField
      fullWidth={false}
      isLabelHidden
      label="Aggregation Period"
      name="intervalPropertyId"
      options={availableIntervalOptions}
    />
  );
}
