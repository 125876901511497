import { isLimitThresholdColumn } from '@monorepo/logs/src/components/Views/ViewResultsTable/resultsMapper';
import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { CheckboxMenu } from '@monorepo/shared/componentsV2/menus/CheckboxMenu';
import { ColumnType, QueryColumn } from 'mapistry-shared';
import { LimitColumnSet } from 'mapistry-shared/dist/dto/edp/QueryRequest';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const COLUMN_TYPES_ALLOWED_IN_FILTERS = [
  ColumnType.BOOLEAN,
  ColumnType.FOREIGN_ID,
  ColumnType.TEXT,
];

function canColumnBeFilteredBy(
  column: QueryColumn,
  limitColumnSets: LimitColumnSet[],
) {
  return (
    COLUMN_TYPES_ALLOWED_IN_FILTERS.includes(column.columnType) &&
    !column.isHidden &&
    !isLimitThresholdColumn(column.columnName, limitColumnSets)
  );
}

const StyledCheckboxMenu = styled(CheckboxMenu)`
  margin-top: 1rem;
`;

type LimitFilterPickerProps = {
  limitFilterColumnNames: string[];
  onLimitFilterChecked: (columnName: string, newIsChecked: boolean) => void;
};

export function LimitFilterPicker({
  limitFilterColumnNames,
  onLimitFilterChecked,
}: LimitFilterPickerProps) {
  const { availableColumns, limitColumnSets } = useSingleQueryStep();
  const checkboxOptions = useMemo(
    () =>
      availableColumns
        .filter((c) => canColumnBeFilteredBy(c, limitColumnSets))
        .map((c) => ({
          isChecked: limitFilterColumnNames.includes(c.columnName),
          label: c.columnLabel,
          value: c.columnName,
        }))
        .sort((op1, op2) => op1.label.localeCompare(op2.label)),
    [availableColumns, limitColumnSets, limitFilterColumnNames],
  );

  if (!checkboxOptions.length) {
    return null;
  }

  return (
    <StyledCheckboxMenu
      buttonLabel="Add criteria"
      onCheck={onLimitFilterChecked}
      options={checkboxOptions}
    />
  );
}
