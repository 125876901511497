import LaunchIcon from '@material-ui/icons/Launch';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { Link } from '@monorepo/shared/componentsV2/Link';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { MenuButton } from '@monorepo/shared/componentsV2/menus/MenuButton';
import { getFilterAsText } from '@monorepo/shared/componentsV2/logs/FrequencyRequirementFilterDisplay';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { useLog } from '@monorepo/shared/hooks/logs/useLog';
import { useLogEntryFields } from '@monorepo/shared/hooks/logs/useLogFields';
import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import { verticalLayout } from '@monorepo/shared/styles/layout';
import { header4 } from '@monorepo/shared/styles/text';
import { format } from 'date-fns';
import {
  CustomFrequencyMessage,
  FieldResponse,
  FrequencyRequirementFilterResponse,
  FrequencyResponse,
  IntervalFrequencyFactory,
  localEquivalentOfUTC,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useGroupedByFrequencyRequirementLogEvents } from '../../hooks/useGroupedByFrequencyRequirementLogEvents';
import { sortByStatusThenAlphabetically } from '../ComplianceCalendar/sortComplianceCalendarEventsHelper';
import { EventStatusIndicator } from '../EventStatusIndicator';

const BaseModalAllowingMenuButtonPopoverToSpillOutside = styled(BaseModal)`
  & .MuiPaper-root {
    overflow: visible;
  }
`;

const Section = styled.section`
  ${verticalLayout}
  align-items: start;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

const SectionHeader = styled.h3`
  ${header4}
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0;
`;

const SectionContent = styled.ul`
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1rem;
`;

const FrequencyRequirementRow = styled.li`
  display: flex;
  align-items: self-start;
  margin-top: 1rem;
`;

const StatusIcon = styled(EventStatusIndicator)`
  margin-right: 0.5rem;
`;

const Counts = styled.div`
  margin-right: 0.5rem;
  font-weight: 900;
`;

function formatFrequency(frequency: FrequencyResponse): string {
  return frequency.customFrequency
    ? new CustomFrequencyMessage(
        frequency.intervalFrequency,
        frequency.customFrequency,
      ).getMessage({ messageStart: '', omitPeriodPunctuation: true })
    : IntervalFrequencyFactory.For(frequency.intervalFrequency).toString();
}

function formatFrequencyRequirementFilters(
  filters: FrequencyRequirementFilterResponse[],
  logFields: FieldResponse[],
) {
  if (!filters.length) return '';

  const formattedFilters = filters.map((filter) =>
    getFilterAsText(filter, logFields),
  );
  return `where ${formattedFilters.join(' and ')}`;
}

interface LogEventsModalProps {
  dueDate: string | null;
  logId: string;
  onClose: () => void;
  openLogEntryModal: () => void;
  openUploadModal: () => void;
}

export function LogEventsModal({
  dueDate,
  logId,
  onClose,
  openLogEntryModal,
  openUploadModal,
}: LogEventsModalProps) {
  const { project } = useCurrentProject();
  const organizationId = project?.organization_id;
  const { isLoading: isLogLoading, log } = useLog({ logId, organizationId });
  const { isLoading: areFieldsLoading, logFields } = useLogEntryFields({
    logId,
    organizationId,
  });
  const {
    isLoading: areEventsLoading,
    groupedEvents,
    asNeededEvents,
  } = useGroupedByFrequencyRequirementLogEvents({
    dueDate,
    logId,
    projectId: project?.id,
  });
  const canSubmitDataForProject = useHasProjectUpdatePermissions(project?.id);

  const showCounts = useMemo(() => {
    const moreThanOneSubmission = groupedEvents.some(
      (g) => g.completedEventCount > 1,
    );
    const moreThanOneRequired = groupedEvents.some(
      (g) => g.requiredEventCount > 1,
    );
    return moreThanOneSubmission || moreThanOneRequired;
  }, [groupedEvents]);
  const sortedFriendlyMessages = useMemo(() => {
    if (!logFields || !groupedEvents.length) return [];
    const messages = groupedEvents.map((ge) => {
      const counts = `${ge.completedEventCount}/${ge.requiredEventCount}`;
      const frequencyMsg = formatFrequency(ge.frequencyRequirement.frequency);
      const filterMsg = formatFrequencyRequirementFilters(
        ge.frequencyRequirement.filters,
        logFields,
      );
      const fullTitle = showCounts
        ? `${counts} ${frequencyMsg} ${filterMsg}`
        : `${frequencyMsg} ${filterMsg}`;
      return {
        frequencyId: ge.frequencyRequirement.id,
        counts,
        frequencyMsg,
        filterMsg,
        title: fullTitle,
        status: ge.status,
      };
    });
    return messages.sort(sortByStatusThenAlphabetically);
  }, [groupedEvents, logFields, showCounts]);

  const numberOfRequiredEvents = useMemo(
    () =>
      groupedEvents.reduce((sum, group) => sum + group.requiredEventCount, 0),
    [groupedEvents],
  );

  const handleOpenUploadModal = useCallback(() => {
    openUploadModal();
    onClose();
  }, [onClose, openUploadModal]);

  const handleOpenLogEntryModal = useCallback(() => {
    openLogEntryModal();
    onClose();
  }, [onClose, openLogEntryModal]);

  const formattedDueDate = useMemo(
    () => (dueDate ? format(localEquivalentOfUTC(new Date(dueDate)), 'P') : ''),
    [dueDate],
  );

  const isLoading = isLogLoading || areFieldsLoading || areEventsLoading;
  const ariaListLabelId = 'event-list';

  return (
    <BaseModalAllowingMenuButtonPopoverToSpillOutside
      buttons={[
        <Button
          color="primary"
          component={Link}
          key="goToLogRecords"
          to={`../../logs/${logId}/records`}
          variant="outlined"
        >
          <LaunchIcon />
          View Log Records
        </Button>,
        canSubmitDataForProject ? (
          <MenuButton
            disablePopperPortal
            key="addEvents"
            label="Add new entry"
            menuWidthShouldMatchButtonEl
            options={[
              {
                onClick: handleOpenLogEntryModal,
                displayName: 'Add manually',
              },
              {
                onClick: handleOpenUploadModal,
                displayName: 'Upload file',
              },
            ]}
          />
        ) : null,
      ]}
      onClose={onClose}
      open
      showCloseButton
      title={isLogLoading ? '...' : log?.name || 'Unknown Log'}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Section>
          <SectionHeader id={ariaListLabelId}>
            {(!asNeededEvents.length || !!groupedEvents.length) &&
              (groupedEvents.length
                ? `${numberOfRequiredEvents} entries due ${formattedDueDate}:`
                : `There are no events for this due date ${
                    formattedDueDate ? `(${formattedDueDate})` : ''
                  }`)}
          </SectionHeader>
          <SectionContent aria-labelledby={ariaListLabelId}>
            {sortedFriendlyMessages.map((msg) => (
              <FrequencyRequirementRow key={msg.frequencyId}>
                {/* spaces are for screen readers */}
                <StatusIcon status={msg.status} />{' '}
                {showCounts && <Counts>{msg.counts} </Counts>}
                {`${msg.frequencyMsg} ${msg.filterMsg}`}
              </FrequencyRequirementRow>
            ))}
          </SectionContent>
          {!!asNeededEvents.length && (
            <SectionHeader>
              {asNeededEvents.length === 1
                ? '1 entry submitted as needed.'
                : `${asNeededEvents.length} entries submitted as needed.`}
            </SectionHeader>
          )}
        </Section>
      )}
    </BaseModalAllowingMenuButtonPopoverToSpillOutside>
  );
}
