import Autolinker, { HtmlTag } from 'autolinker';
import React, { ReactNode } from 'react';

type ReactAutolinkerOptions = {
  textToLink: string;
};

// Derived from https://github.com/moudy/react-autolinker
export const ReactAutolinker = (props: ReactAutolinkerOptions) => {
  const { textToLink } = props;
  const tags: HtmlTag[] = [];
  Autolinker.link(textToLink, {
    replaceFn: (match) => {
      const tag = match.buildTag();
      tags.push(tag);
      return tag;
    },
  });
  let text = textToLink;
  const children: ReactNode[] = [];
  tags.forEach((tag) => {
    const attrs = tag.getAttrs();
    const { href } = attrs;
    const hrefOrText = href && text.includes(href) ? href : tag.getInnerHtml();
    const parts = text.split(hrefOrText);
    const part = parts.shift();
    children.push(part);
    children.push(
      React.createElement(tag.getTagName(), tag.getAttrs(), hrefOrText),
    );
    text = parts.join(attrs.href);
  });
  children.push(text);
  return React.createElement(React.Fragment, null, children);
};
