import React from 'react';
import { SectionHeader, Subtext } from './styled';

export function GroupBySectionHeader() {
  return (
    <>
      <SectionHeader>Group By</SectionHeader>
      <Subtext>
        Split out your aggregated data by one or more columns of your choosing.
      </Subtext>
    </>
  );
}
