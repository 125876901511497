import { ActionMenu } from '@monorepo/shared/componentsV2/menus/ActionMenu';
import { ViewType } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useEditViewInfoModal } from '../../hooks/useEditViewInfoModal';
import { useViewDeleteWithConfirm } from '../../hooks/useViewDelete';

interface ViewsTableActionsProps {
  viewId: string;
  viewOriginId: string;
  viewName: string;
  viewType: ViewType;
}

export function ViewsTableActions({
  viewId,
  viewOriginId,
  viewName,
  viewType,
}: ViewsTableActionsProps) {
  const { organizationId, projectId } = useParams();
  const { openModal } = useEditViewInfoModal();
  const handleDelete = useViewDeleteWithConfirm({
    organizationId,
    projectId,
    viewId,
    viewName,
  });

  const handleEditDetails = useCallback(
    () => openModal({ existingViewId: viewId, viewOriginId, viewType }),
    [openModal, viewOriginId, viewId, viewType],
  );

  return (
    <ActionMenu
      altText={`Action menu for ${viewName} View`}
      options={[
        { displayName: 'Edit details', onClick: handleEditDetails },
        { displayName: 'Delete', onClick: handleDelete },
      ]}
    />
  );
}
