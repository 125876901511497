import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import styled from 'styled-components';

const CenteredText = styled.div`
  display: flex;
  align-items: center;
  height: 1.875rem;
`;

const valueOptions: SelectableOption<'true' | 'false' | 'null'>[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
  { label: 'Empty', value: 'null' },
];

export function BooleanFilterCondition({ name }: { name: string }) {
  const { change, getFieldState } = useForm();
  const valueFieldState = getFieldState(`${name}.value`);

  useEffect(() => {
    if (valueFieldState && valueFieldState.value == null) {
      // Filter condition is new, default the value to 'Yes'
      change(`${name}.value`, 'true');
    }
  }, [change, name, valueFieldState]);

  return (
    <>
      <CenteredText>
        <div>is</div>
      </CenteredText>
      <SelectField
        isLabelHidden
        label="Value"
        name={`${name}.value`}
        options={valueOptions}
        required
        validate={isRequired}
      />
    </>
  );
}
