import {
  FormulaOutputType,
  FormulaValue,
  isUnitAwareNumber,
} from 'mapistry-shared';
import React from 'react';
import { ReactAutolinker } from '../../ReactAutolinker';

export const formulaDisplayFactory = ({ outputType, value }: FormulaValue) => {
  const defaultResult = '';
  if (value == null) {
    return defaultResult;
  }
  switch (outputType) {
    case FormulaOutputType.BOOLEAN:
      return value ? 'Yes' : 'No';
    case FormulaOutputType.NUMBER:
      return isUnitAwareNumber(value)
        ? `${value.value} ${value.unit}`
        : `${value}`;
    case FormulaOutputType.TEXT: {
      return (
        <ReactAutolinker textToLink={(value as string) || defaultResult} />
      );
    }
    default:
      return defaultResult;
  }
};
