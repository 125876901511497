import { ComboboxField } from '@monorepo/shared/componentsV2/fields/ComboboxField';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import { useProjectUserOptions } from '@monorepo/shared/hooks/useProjectUserOptions';
import {
  BaseFieldResponse,
  SaveFieldValueRequest,
  SaveUserValue,
  UserValue,
} from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormValues, isNullableString } from './types';
import {
  getUnexpectedTypeErrorAndLogToSentry,
  getValidateFunction,
} from './utils';

const StyledCombobox = styled(ComboboxField)`
  width: 18rem;
`;

type EditUserFieldValueProps = {
  className?: string;
  fieldValue?: UserValue;
  isRequired: boolean;
  label: string;
  name: string;
  projectId: string;
};

export function EditUserFieldValue({
  className,
  fieldValue,
  isRequired,
  label,
  name,
  projectId,
}: EditUserFieldValueProps) {
  const savedValue = fieldValue?.value?.id;

  const { userOptions = [], isLoading: projectUsersAreLoading } =
    useProjectUserOptions({
      projectId,
      previouslySavedUserId: savedValue,
    });

  const { currentUser, isLoading: currentUserIsLoading } = useCurrentUser();
  // if null was saved for the user field value, do not default to the current user
  const initialValue = fieldValue ? savedValue : currentUser?.id;

  const options = useMemo(
    () =>
      userOptions.slice().sort((p1, p2) => p1.label.localeCompare(p2.label)),
    [userOptions],
  );

  return (
    <StyledCombobox
      autoHighlight
      className={className}
      initialValue={initialValue}
      label={label}
      isLoading={projectUsersAreLoading || currentUserIsLoading}
      loadingText="Loading users"
      name={name}
      options={options}
      required={isRequired}
      validate={getValidateFunction(isRequired)}
    />
  );
}

export function getUserFieldValueFromForm(
  field: BaseFieldResponse,
  formValues: FormValues,
): SaveFieldValueRequest {
  const value = formValues[field.id] || null;
  if (!isNullableString(value)) {
    throw getUnexpectedTypeErrorAndLogToSentry(field, value);
  }

  const fieldValue: SaveUserValue = { value };

  return {
    fieldId: field.id,
    fieldValue,
  };
}
