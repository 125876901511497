import { AggregationInterval } from 'mapistry-shared';

export const aggregationIntervalsInGrowingOrder = [
  AggregationInterval.DAY,
  AggregationInterval.WEEK,
  AggregationInterval.MONTH,
  AggregationInterval.QUARTER,
  AggregationInterval.YEAR,
];

export const aggregationIntervalLabels: {
  [key in AggregationInterval]: string;
} = {
  [AggregationInterval.DAY]: 'Days',
  [AggregationInterval.MONTH]: 'Months',
  [AggregationInterval.QUARTER]: 'Quarters',
  [AggregationInterval.SEMI_ANNUAL]: 'Semi-Annual',
  [AggregationInterval.WEEK]: 'Weeks',
  [AggregationInterval.YEAR]: 'Years',
};
