import { AggregationMethod } from 'mapistry-shared';

export const aggregationMethodLabels: { [key in AggregationMethod]: string } = {
  [AggregationMethod.AVERAGE]: 'Average',
  [AggregationMethod.COUNT]: 'Count',
  [AggregationMethod.COUNT_DISTINCT]: 'Unique values count',
  [AggregationMethod.MAX]: 'Maximum',
  [AggregationMethod.MIN]: 'Minimum',
  [AggregationMethod.SUM]: 'Sum',
};

export const DEFAULT_AGGREGATION_METHOD = AggregationMethod.SUM;
