import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import {
  WorkflowCacheEvents,
  workflowCacheRegister,
} from '../../cacheRegisters/data2/workflowCacheRegister';

type KeyParams = Api.FetchWorkflowParams;

const KEY_START = 'org-workflow' as const;
export const createKey = ({ organizationId, workflowId }: KeyParams) =>
  [KEY_START, organizationId, workflowId] as const;

type Fetcher = Api.DataHookQueryFn<typeof createKey, typeof Api.fetchWorkflow>;

const fetcher: Fetcher = (_, organizationId, workflowId) =>
  Api.fetchWorkflow({ organizationId, workflowId });

type UseWorkflowParams = Partial<Api.FetchWorkflowParams> & {
  config?: QueryConfig<Api.FetchWorkflowResponse, Api.ErrorResponse>;
};

export function useWorkflow({
  config: inputConfig,
  organizationId,
  workflowId,
}: UseWorkflowParams) {
  const isEnabled = !!organizationId && !!workflowId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ organizationId, workflowId }) : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    workflow: data,
  };
}

type QueryCache = ReturnType<typeof useWorkflow>['workflow'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

type InvalidateCacheParams = Omit<KeyParams, 'workflowId'> &
  Partial<Pick<KeyParams, 'workflowId'>>;

export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.organizationId,
      keyParams.workflowId,
    ]),
  );
}

workflowCacheRegister(
  [WorkflowCacheEvents.CREATE, WorkflowCacheEvents.UPDATE],
  {
    hookName: 'useWorkflow',
    // defensively only accepting the org parameter to make sure that if one workflow updates,
    // all workflows on that org get invalidated, since workflows can be on top of each other and
    // changes to one affect the other
    callback: ({ organizationId }) => invalidateCache({ organizationId }),
  },
);
