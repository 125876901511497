import { Skeleton } from '@material-ui/lab';
import {
  ColumnSet,
  GroupedColumn,
  LimitColumnSet,
  QueryColumn,
} from 'mapistry-shared';
import React, { createContext, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useQueryResults } from '../hooks/useQueryResults';
import { PotentiallyIncompleteQueryStep } from '../types';
import { useQuerySteps } from './QueryStepsContext';

const StyledSkeleton = styled(Skeleton)`
  margin-bottom: 0.2rem;
`;

export type SingleQueryStepContext = {
  availableColumns: QueryColumn[];
  columnSets: ColumnSet[];
  dateColumn?: string;
  groupedColumns: GroupedColumn[];
  index: number;
  isLastStep: boolean;
  limitColumnSets: LimitColumnSet[];
  queryStep: PotentiallyIncompleteQueryStep;
};

export const SingleQueryStepContext = createContext<
  SingleQueryStepContext | undefined
>(undefined);

type SingleQueryStepProviderProps = Pick<SingleQueryStepContext, 'index'> & {
  children: React.ReactNode;
};

export const SingleQueryStepProvider = ({
  children,
  index,
}: SingleQueryStepProviderProps) => {
  const {
    originId,
    organizationId,
    getInProgressQuerySteps,
    projectId,
    validQuerySteps,
  } = useQuerySteps();
  const queryStep = getInProgressQuerySteps()[index];

  if (!queryStep) {
    throw new Error('No query step at provided index');
  }

  const {
    isLoading,
    queryResult: {
      columns: availableColumns = [],
      columnSets = [],
      dateColumn,
      groupedColumns = [],
      limitColumnSets = [],
    } = {},
  } = useQueryResults({
    originId,
    organizationId,
    projectId,
    query: { steps: validQuerySteps.slice(0, index) },
  });

  const isLastStep = getInProgressQuerySteps().length - 1 === index;

  const contextValue = useMemo(
    () => ({
      availableColumns,
      columnSets,
      dateColumn,
      groupedColumns,
      index,
      isLastStep,
      limitColumnSets,
      queryStep,
    }),
    [
      availableColumns,
      columnSets,
      dateColumn,
      groupedColumns,
      index,
      isLastStep,
      limitColumnSets,
      queryStep,
    ],
  );

  return isLoading ? (
    <StyledSkeleton variant="rect" height="3rem" />
  ) : (
    <SingleQueryStepContext.Provider value={contextValue}>
      {children}
    </SingleQueryStepContext.Provider>
  );
};

export function useSingleQueryStep(): SingleQueryStepContext {
  const contextValue = useContext<SingleQueryStepContext | undefined>(
    SingleQueryStepContext,
  );
  if (!contextValue)
    throw new Error(
      'must use useSingleQueryStep within a SingleQueryStepProvider',
    );
  return contextValue;
}
