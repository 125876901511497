import { Api } from '@monorepo/shared/apiClient';
import {
  LogEntryCacheEvents,
  logEntryCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFieldCacheRegister';
import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  ResourcePropertyCacheEvents,
  resourcePropertyCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceTypeCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { QueryRequest } from 'mapistry-shared';
import { queryCache, QueryConfig, usePaginatedQuery } from 'react-query';
import { OptionalKeys } from 'utility-types';
import {
  WorkflowCacheEvents,
  workflowCacheRegister,
} from '../../../shared/cacheRegisters/data2/workflowCacheRegister';

type KeyParams = Omit<
  Api.FetchQueryResultsParams,
  OptionalKeys<Api.FetchQueryResultsParams>
>;

const KEY_START = 'queryResults';

export const createQueryKey = ({
  originId,
  organizationId,
  projectId,
  query,
}: KeyParams) =>
  [KEY_START, organizationId, originId, projectId, query] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchQueryResults
>;

type UseQueryResultsParams = Partial<Api.FetchQueryResultsParams> & {
  config?: QueryConfig<Api.FetchQueryResultsResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  originId: string,
  projectId: string,
  query: QueryRequest,
) =>
  Api.fetchQueryResults({
    originId,
    organizationId,
    projectId,
    query,
  });

export const useQueryResults = ({
  config: inputConfig,
  originId,
  organizationId,
  projectId,
  query,
}: UseQueryResultsParams) => {
  const isEnabled = !!organizationId && !!originId && !!projectId && !!query;
  const config = getQueryConfig<Api.FetchQueryResultsResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({
        originId,
        organizationId,
        projectId,
        query,
      })
    : undefined;
  const { resolvedData: queryResult, ...queryInfo } = usePaginatedQuery(
    key,
    fetcher,
    config,
  );
  return {
    queryResult,
    ...queryInfo,
  };
};

type QueryCache = ReturnType<typeof useQueryResults>['queryResult'] | undefined;
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Omit<
  KeyParams,
  'originId' | 'projectId' | 'query'
> & {
  originId?: string;
  projectId?: string;
};

// allow invalidating all entries lists (across projects) for a log
async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.organizationId,
      keyParams.originId,
      keyParams.projectId,
    ]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useQueryResults',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

logEntryCacheRegister(
  [
    LogEntryCacheEvents.CREATE,
    LogEntryCacheEvents.UPDATE,
    LogEntryCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

logFieldCacheRegister(
  [
    LogFieldCacheEvents.CREATE,
    LogFieldCacheEvents.DELETE,
    LogFieldCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourceCacheRegister(
  [
    ResourceCacheEvents.CREATE,
    ResourceCacheEvents.DELETE,
    ResourceCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourcePropertyCacheRegister(
  [
    ResourcePropertyCacheEvents.CREATE,
    ResourcePropertyCacheEvents.DELETE,
    ResourcePropertyCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourceTypeCacheRegister(
  [
    ResourceTypeCacheEvents.CREATE,
    ResourceTypeCacheEvents.DELETE,
    ResourceTypeCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

workflowCacheRegister([WorkflowCacheEvents.UPDATE], cacheRegisterInvalidator);
