import { SelectOption } from '@monorepo/shared/componentsV2/Select';
import AddIcon from '@svg/add.svg';
import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { DeepPartial } from 'utility-types';
import { Button } from '../../Button';
import { AnyFieldValues } from '../types';
import { AggregationGroupBySelect } from './AggregationGroupBySelect';

type AggregationGroupByFieldArrayProps<FormValues extends AnyFieldValues> = {
  canEdit?: boolean;
  fieldName: Extract<keyof FormValues, string>;
  getAllowedGroupByColumnOptions: (
    formValues: DeepPartial<FormValues>,
    fieldIndex?: number,
  ) => SelectOption[];
};

export function AggregationGroupByFieldArray<
  FormValues extends AnyFieldValues,
>({
  canEdit = true,
  getAllowedGroupByColumnOptions,
  fieldName,
}: AggregationGroupByFieldArrayProps<FormValues>) {
  const { values: currentFormValues } = useFormState<DeepPartial<FormValues>>();

  // are there any remaining options for columns that can be grouped by,
  // or do we have at least one column we're already grouping by?
  const stepHasColumnsForGrouping = useMemo(
    () =>
      getAllowedGroupByColumnOptions(currentFormValues).length > 0 ||
      currentFormValues?.[fieldName]?.length,
    [currentFormValues, fieldName, getAllowedGroupByColumnOptions],
  );

  return stepHasColumnsForGrouping ? (
    <FieldArray name={fieldName}>
      {({ fields: formFields }) => (
        <>
          {formFields.map((formFieldName, formFieldIndex) => (
            <AggregationGroupBySelect<FormValues>
              canEdit={canEdit}
              formFieldName={formFieldName}
              formFieldIndex={formFieldIndex}
              getAllowedGroupByColumnOptions={getAllowedGroupByColumnOptions}
              key={formFieldName}
              onDelete={
                canEdit ? () => formFields.remove(formFieldIndex) : undefined
              }
            />
          ))}
          {canEdit && (
            <Button
              color="primary"
              variant="text"
              onClick={() => formFields.push(undefined)}
            >
              <AddIcon />
              Add group by
            </Button>
          )}
        </>
      )}
    </FieldArray>
  ) : (
    <span>There are no columns available to group by.</span>
  );
}
