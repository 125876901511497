import { fetchCalendarEvents } from '@monorepo/old-web/js/actions/calendar';
import apiCaller from '@monorepo/old-web/js/apiCaller';
import {
  FormCalendar,
  MenuOptionType,
} from '@monorepo/old-web/js/components/propTypes';
import { withParamsAndNavigate } from '@monorepo/old-web/js/components/routing/withReactRouter';
import withProvider from '@monorepo/old-web/js/components/withProvider';
import APP from '@monorepo/old-web/js/config';
import { withPermissions } from '@monorepo/shared/hooks/permissions/withPermissions';
import DocumentIcon from '@svg/document.svg';
import DownloadIcon from '@svg/download.svg';
import { CalendarEventStatus } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CalendarCard from '../CalendarCard';
import NeverEventCalendar from '../CalendarCard/NeverEventCalendar';
import { CannotEditFormTemplateModal } from '../CannotEditFormTemplateModal';
import DownloadInspectionsModal from './DownloadInspectionsModal';
import { InspectionEventChoiceMenu } from './InspectionEventChoiceMenu';

class BaseInspectionCalendarCard extends Component {
  constructor(props) {
    super(props);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleIsDownloadModalOpen = this.handleIsDownloadModalOpen.bind(this);
    this.handleDownloadModalClose = this.handleDownloadModalClose.bind(this);
    this.handleOpenFormTemplate = this.handleOpenFormTemplate.bind(this);
    this.state = {
      isDownloadModalOpen: false,
      eventChoices: [],
      eventChoiceTarget: null,
      isCannotEditFormTemplateModalOpen: false,
    };
  }

  handleIsDownloadModalOpen() {
    this.setState({ isDownloadModalOpen: true });
  }

  handleDownloadModalClose() {
    this.setState({ isDownloadModalOpen: false });
  }

  handleOpenFormTemplate() {
    const { navigate, calendar } = this.props;
    if (calendar.canEditTemplate) {
      navigate(`../../form-templates/${calendar.formTemplateSlug}/edit`);
    } else {
      this.setState({ isCannotEditFormTemplateModalOpen: true });
    }
  }

  handleEventClick(calendarEvents, clickEvent) {
    const { calendar, projectId } = this.props;
    if (
      calendarEvents.length === 1 ||
      calendarEvents.every((e) => e.attachments.every((a) => a.isDefault))
    ) {
      // should only have 1 attachment, if there are 2, it is safe to use the first one for now
      const calendarEvent = calendarEvents[0];
      const attachment = calendarEvent.attachments[0];
      const eventDate = calendarEvent.dueDate || calendarEvent.startDate;
      const submissionUrl = apiCaller.getFormSubmissionUrl(
        projectId,
        attachment.formTemplateSlug,
        attachment.formSubmissionSlug,
        calendar.name,
        eventDate,
        attachment.eventNumber,
      );

      window.location.href = submissionUrl;
    } else {
      this.setState({
        eventChoices: calendarEvents,
        eventChoiceTarget: clickEvent.currentTarget,
      });
    }
  }

  helpTextForEvent(event, tooMuchData) {
    if (tooMuchData) {
      return 'More Details';
    }
    switch (event.status) {
      case CalendarEventStatus.COMPLETE:
        return 'Completed inspection';
      case CalendarEventStatus.OVERDUE:
        return 'Missed inspection';
      case CalendarEventStatus.UPCOMING:
        return 'Inspection due soon';
      case CalendarEventStatus.NOT_NEEDED:
        return 'Not Required';
      case CalendarEventStatus.EXTRA:
        return 'Create a new inspection';
      default:
        return 'Future inspection';
    }
  }

  menuOptions() {
    const { calendar, hasOrgUpdatePermissionOrSuperAdmin, menuOptions } =
      this.props;
    const { isDownloadable } = calendar || {};
    const options = [...menuOptions];
    if (hasOrgUpdatePermissionOrSuperAdmin) {
      options.push({
        label: 'Configure Form',
        icon: <DocumentIcon className="m-icon" />,
        onClick: this.handleOpenFormTemplate,
      });
    }
    if (isDownloadable) {
      options.push({
        label: 'Download Inspections',
        icon: <DownloadIcon className="m-icon" />,
        onClick: this.handleIsDownloadModalOpen,
      });
    }
    return options;
  }

  render() {
    const {
      calendar,
      defaultView,
      fetchEvents,
      tooltipData,
      tooltipTitle,
      onDateChange,
    } = this.props;
    const {
      isDownloadModalOpen,
      eventChoices,
      eventChoiceTarget,
      isCannotEditFormTemplateModalOpen,
    } = this.state;

    return (
      <>
        <CalendarCard
          calendarName={calendar.name}
          fetchEvents={fetchEvents}
          helpTextForEvent={this.helpTextForEvent}
          tooltipData={tooltipData}
          tooltipTitle={tooltipTitle}
          menuOptions={this.menuOptions()}
          defaultView={
            defaultView || (
              <NeverEventCalendar calendarTitle={calendar.summaryTitle} />
            )
          }
          onDateChange={onDateChange}
          onEventClick={this.handleEventClick}
          title={calendar.summaryTitle}
        />
        <InspectionEventChoiceMenu
          eventChoices={eventChoices}
          menuAnchor={eventChoiceTarget}
          closeEventChoiceMenu={() =>
            this.setState({ eventChoiceTarget: null })
          }
          onEventClick={(event) => this.handleEventClick([event])}
        />
        {isDownloadModalOpen && (
          <DownloadInspectionsModal
            calendarId={calendar.id}
            onClose={this.handleDownloadModalClose}
            open={isDownloadModalOpen}
            title={`Download ${calendar.summaryTitle}s`}
          />
        )}
        {isCannotEditFormTemplateModalOpen && (
          <CannotEditFormTemplateModal
            open={isCannotEditFormTemplateModalOpen}
            onDone={() =>
              this.setState({ isCannotEditFormTemplateModalOpen: false })
            }
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchEvents: (projectId, calendarName, date) =>
    dispatch(
      fetchCalendarEvents({
        calendarName,
        date,
        projectId,
      }),
    ),
});

const mapStateToProps = () => ({
  projectId: APP.projectId,
});

const FormTemplateDetails = PropTypes.shape({
  canEdit: PropTypes.bool,
  formTemplateSlug: PropTypes.string,
});

BaseInspectionCalendarCard.propTypes = {
  calendar: FormCalendar.isRequired,
  defaultView: PropTypes.node,
  fetchEvents: PropTypes.func.isRequired,
  formTemplateDetails: FormTemplateDetails,
  hasOrgUpdatePermissionOrSuperAdmin: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(MenuOptionType),
  navigate: PropTypes.func.isRequired,
  onDateChange: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  organizationId: PropTypes.string, // needed for withPermissions HOC
  projectId: PropTypes.string.isRequired,
  tooltipData: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  tooltipTitle: PropTypes.func.isRequired,
};

BaseInspectionCalendarCard.defaultProps = {
  defaultView: null,
  menuOptions: [],
  onDateChange: null,
  organizationId: null,
  tooltipData: null,
  formTemplateDetails: { canEdit: false, formTemplateSlug: null },
  hasOrgUpdatePermissionOrSuperAdmin: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withParamsAndNavigate(withPermissions(BaseInspectionCalendarCard))),
);
