import { Api } from '@monorepo/shared/apiClient';
import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

type KeyPartsAllDefined = [string, string, string, string, string];
type KeyPartsPartial = [
  string,
  string | undefined,
  string | undefined,
  string | undefined,
  string | undefined,
];

type KeyParams = Api.FetchQueryColumnOptionsParams;
const KEY_START = 'queryColumnOptions';

function getKeyPartsInOrder(keyParams: KeyParams): KeyPartsAllDefined;
function getKeyPartsInOrder(keyParams: Partial<KeyParams>): KeyPartsPartial;
function getKeyPartsInOrder(
  keyParams: KeyParams | Partial<KeyParams>,
): KeyPartsAllDefined | KeyPartsPartial {
  return [
    KEY_START,
    keyParams.organizationId,
    keyParams.projectId,
    keyParams.queryOriginId,
    keyParams.columnOriginId,
  ];
}
export const createQueryKey = (keyParams: KeyParams) =>
  getKeyPartsInOrder(keyParams);

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchQueryColumnOptions
>;

type UseQueryColumnOptionsParams =
  Partial<Api.FetchQueryColumnOptionsParams> & {
    config?: QueryConfig<
      Api.FetchQueryColumnOptionsResponse,
      Api.ErrorResponse
    >;
  };

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  projectId: string,
  queryOriginId: string,
  columnOriginId: string,
) =>
  Api.fetchQueryColumnOptions({
    organizationId,
    projectId,
    queryOriginId,
    columnOriginId,
  });

export const useQueryColumnOptions = ({
  config: inputConfig,
  organizationId,
  projectId,
  queryOriginId,
  columnOriginId,
}: UseQueryColumnOptionsParams) => {
  const isEnabled =
    !!organizationId && !!projectId && !!queryOriginId && !!columnOriginId;
  const config = getQueryConfig<Api.FetchQueryColumnOptionsResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({
        organizationId,
        projectId,
        queryOriginId,
        columnOriginId,
      })
    : undefined;
  const { data: columnOptions, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    columnOptions,
    ...queryInfo,
  };
};

type InvalidateCacheParams = Pick<KeyParams, 'organizationId'> &
  Partial<Omit<KeyParams, 'organizationId'>>;

export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation(getKeyPartsInOrder(keyParams)),
  );
}

resourceCacheRegister(
  [
    ResourceCacheEvents.CREATE,
    ResourceCacheEvents.UPDATE,
    ResourceCacheEvents.DELETE,
  ],
  {
    hookName: 'useQueryColumnOptions',
    callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
  },
);
