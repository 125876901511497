import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { bodyMedium } from '../styles/text';
import { Tooltip } from './Tooltip';
import { VisuallyHidden } from './VisuallyHidden';

const leftRightPadding = '0.5rem';
const borderWidthInPx = 1;

const SelectContainer = styled(MuiSelect)`
  height: 2rem;
  font-size: 0.875rem;
  border: ${borderWidthInPx}px solid ${({ theme }) => theme.colors.grayddd};
  border-radius: 4px;

  label + & {
    margin-top: 1.2rem;
  }

  &:not(.Mui-disabled) {
    &.Mui-focused,
    &:hover {
      border-color: ${({ theme }) => theme.colors.gray333};
    }
  }

  &.Mui-error {
    border-color: ${({ theme }) => theme.colors.darkRed};
  }

  /* child div that the menu is the anchor element for the menu */
  & .MuiSelect-root {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 ${leftRightPadding};
  }
`;

const SelectLabel = styled(MuiInputLabel)`
  ${bodyMedium}
  position: static;
  color: ${({ theme }) => theme.colors.gray333};
  transform: none;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray333};
  }

  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

const MenuItem = styled(MuiMenuItem)`
  padding-right: ${leftRightPadding};
  padding-left: ${leftRightPadding};
  font-size: 0.875rem;
`;

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.gray999};
`;

const HelperText = styled(FormHelperText)`
  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

const InputLabelContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const NoOptionsText = styled.div`
  padding: 0.5rem;
`;

type SelectValue = React.ComponentProps<typeof MuiMenuItem>['value'];

export type SelectOption = {
  label: string;
  value: Exclude<SelectValue, undefined>;
};

type SelectProps = React.ComponentProps<typeof MuiSelect> & {
  dontSaveSpaceForErrorText?: boolean;
  errorText?: string;
  isLabelHidden?: boolean;
  helperText?: string;
  label?: string;
  name: string;
  noOptionsText?: string;
  options: SelectOption[];
  showBlankOption?: boolean;
  tooltipText?: string;
};

function getValueHash(value: SelectValue) {
  // we have to hash the value in case it's a multiselect select (where value is an array)
  return JSON.stringify(value);
}

export function Select({
  className,
  dontSaveSpaceForErrorText,
  error,
  errorText,
  isLabelHidden,
  helperText,
  label,
  name,
  noOptionsText = 'Nothing to select',
  options,
  placeholder,
  required,
  showBlankOption = false,
  tooltipText,
  ...props
}: SelectProps) {
  const [idRandomify] = useState(uuidv4);

  // we need to have special rendering for inside the select in order to
  // support a placeholder either - show the label of the selected value,
  // or show the placeholder
  const renderValueOrPlaceholder = useCallback(
    (val) => {
      const optLabel = options.find((opt) => opt.value === val)?.label;
      // if a value is selected, render that value's label
      // otherwise, render the placeholder
      return optLabel || <Placeholder>{placeholder}</Placeholder>;
    },
    [placeholder, options],
  );

  const elementId = `${name || label}-${idRandomify}`;
  const labelId = `${elementId}-label`;
  const helperTextId = `${elementId}-helper-text`;

  const labelComponent = (
    <SelectLabel id={labelId} required={required}>
      {label || name}
    </SelectLabel>
  );

  return (
    <FormControl fullWidth error={error} className={className}>
      {isLabelHidden ? (
        <VisuallyHidden>{labelComponent}</VisuallyHidden>
      ) : (
        <InputLabelContainer>
          {labelComponent}
          {tooltipText && <Tooltip title={tooltipText} placement="right" />}
        </InputLabelContainer>
      )}
      <SelectContainer
        id={elementId}
        labelId={labelId}
        aria-describedby={helperText ? helperTextId : undefined}
        // place menu under the select container
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            // leave extra room under the anchor element so it doesn't cover the border
            vertical: -1 * borderWidthInPx,
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        disableUnderline
        displayEmpty
        renderValue={placeholder ? renderValueOrPlaceholder : undefined}
        required={required}
        {...props}
      >
        {showBlankOption && <MenuItem value="">&nbsp;</MenuItem>}
        {options.map((option) => (
          <MenuItem key={getValueHash(option.value)} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {!showBlankOption && !options.length && (
          <NoOptionsText>{noOptionsText}</NoOptionsText>
        )}
      </SelectContainer>
      <HelperText id={helperTextId}>
        {errorText || helperText || (dontSaveSpaceForErrorText ? '' : ' ')}
      </HelperText>
    </FormControl>
  );
}
