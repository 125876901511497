import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { PopperPlacementType } from '@material-ui/core/Popper';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { useAnchorElement } from '@monorepo/shared/componentsV2/menus/useAnchorElement';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Badge } from '../Badge';
import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox';
import {
  genericMenuButtonStyles,
  genericMenuItemStyles,
  menuButtonArrowDown,
  MenuList,
  PopperContainer,
} from './styled';

const StyledButton = styled(Button)`
  ${genericMenuButtonStyles}

  & .checkbox-menu__arrow-drop-down {
    ${menuButtonArrowDown}
  }
`;

const StyledBadge = styled(Badge)`
  margin-left: 0.5rem;
`;

const MenuItem = styled(MuiMenuItem)`
  ${genericMenuItemStyles}
`;

type CheckboxMenuOption = {
  isChecked: boolean;
  label: string;
  value: string;
};

type CheckboxMenuProps = Partial<
  Pick<React.ComponentProps<typeof Button>, 'variant'>
> & {
  buttonLabel: string;
  className?: string;
  /** e.g. when rendering inside a dialog */
  disablePopperPortal?: boolean;
  onCheck: (value: string, isChecked: boolean) => void;
  options: CheckboxMenuOption[];
  popperPlacement?: PopperPlacementType;
};

export function CheckboxMenu({
  buttonLabel,
  className,
  disablePopperPortal = false,
  onCheck,
  options,
  popperPlacement = 'bottom-start',
  variant,
}: CheckboxMenuProps) {
  const { anchorEl, handleAnchorElClick, handleClose, handleKeyDown } =
    useAnchorElement();

  const numberOfCheckedOptions = useMemo(
    () => options.filter(({ isChecked }) => isChecked).length,
    [options],
  );

  return (
    <>
      <StyledButton
        className={className}
        color="primary"
        onClick={handleAnchorElClick}
        variant={variant || 'outlined'}
      >
        {buttonLabel}
        {numberOfCheckedOptions > 0 && (
          <StyledBadge
            color="secondary"
            label={numberOfCheckedOptions}
            variant="round"
          />
        )}
        <ArrowDropDown aria-hidden className="checkbox-menu__arrow-drop-down" />
      </StyledButton>

      <PopperContainer
        anchorEl={anchorEl}
        disablePortal={disablePopperPortal}
        open={!!anchorEl}
        placement={popperPlacement}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={!!anchorEl}
            variant="menu"
            aria-label={buttonLabel}
          >
            {options.map((opt) => (
              <MenuItem
                key={opt.value}
                onKeyDown={handleKeyDown}
                onClick={(evt) => {
                  // preventing event bubbling, so checkbox label doesn't get a click event resulting in
                  // checkbox input to get click event too. Otherwise, when a checkbox label is clicked,
                  // we register double action on menu item itself that causes check status to stay the same.
                  evt.preventDefault();
                  onCheck(opt.value, !opt.isChecked);
                }}
              >
                <Checkbox
                  checked={opt.isChecked}
                  label={opt.label}
                  name={opt.value}
                />
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </PopperContainer>
    </>
  );
}
