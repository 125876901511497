import { Badge } from '@monorepo/shared/componentsV2/Badge';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { cardBorder } from '@monorepo/shared/styles/card';
import {
  AggregationWorkflowStepResponse,
  WorkflowStepOperationType,
  WorkflowStepResponse,
} from 'mapistry-shared';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { DatasetChip } from '../shared/DatasetChip';
import { getStepDisplayInfo } from './stepUtils';
import { WorkflowStepActions } from './WorkflowStepActions';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  max-width: 70rem;
  padding: 0.625rem;
  ${cardBorder}
`;

const InnerContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  justify-content: right;
  overflow: hidden;
`;

const LabelAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  margin-right: 1rem;
`;

const IconContainer = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.gray666};
  background-color: ${({ theme }) => theme.colors.blue08};
  border-radius: 5px;

  & svg {
    height: 0.8rem;
  }
`;

const Chip = styled(Badge)`
  padding: 0.5rem;
  margin-left: 0.25rem;
  overflow: hidden;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue64};
  background-color: ${({ theme }) => theme.colors.blue08};
`;

const PlusNMoreChip = styled(Chip)`
  flex-shrink: 0;
`;

const ActionButtonContainer = styled.div`
  flex-shrink: 0;
  padding-left: 0.5rem;

  button {
    height: 1.5rem;
  }
`;

function AggregationCardChips({
  step,
}: {
  step: AggregationWorkflowStepResponse;
}) {
  const MAX_CHIP_COUNT = 3;
  const { aggregationDefinitions } = step.operationConfig;
  const aggregationDefinitionNames = aggregationDefinitions.map(
    (ad) => ad.name,
  );
  return (
    <>
      {aggregationDefinitionNames.slice(0, MAX_CHIP_COUNT).map((chipLabel) => (
        <Chip label={chipLabel} key={chipLabel} />
      ))}
      {aggregationDefinitionNames.length > MAX_CHIP_COUNT && (
        <PlusNMoreChip
          label={`+${aggregationDefinitionNames.length - MAX_CHIP_COUNT} more`}
          key="extra chips"
        />
      )}
    </>
  );
}

function getAdditionalInfo(step: WorkflowStepResponse): ReactElement {
  const { type } = step;
  switch (type) {
    case WorkflowStepOperationType.FORMULA:
      return <Chip label={step.operationConfig.name} />;
    case WorkflowStepOperationType.JOIN:
      return <DatasetChip name={step.operationConfig.joinToDataset.name} />;
    case WorkflowStepOperationType.AGGREGATION:
      return <AggregationCardChips step={step} />;
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(
        `Trying to render a Workflow step for an unknown step type ${exhaustiveCheck}`,
      );
    }
  }
}

interface WorkflowStepCardProps {
  className?: string;
  onDeleteSuccess?: () => void;
  openModalForExistingStep: (step: WorkflowStepResponse) => void;
  organizationId: string;
  showMenu: boolean;
  step: WorkflowStepResponse;
}

export function WorkflowStepCard({
  className,
  onDeleteSuccess,
  openModalForExistingStep,
  organizationId,
  showMenu,
  step,
}: WorkflowStepCardProps) {
  const { icon, title } = getStepDisplayInfo(step);
  const additionalInfo = getAdditionalInfo(step);

  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

  return (
    <Container className={className}>
      <LabelAndIcon>
        <IconContainer aria-hidden>{icon}</IconContainer>
        {title}
      </LabelAndIcon>
      <InnerContainer>
        {additionalInfo}
        {showMenu && canEditOrgSettings && (
          <ActionButtonContainer>
            <WorkflowStepActions
              organizationId={organizationId}
              step={step}
              openModalForExistingStep={openModalForExistingStep}
              onDeleteSuccess={onDeleteSuccess}
            />
          </ActionButtonContainer>
        )}
      </InnerContainer>
    </Container>
  );
}
