import { RadioGroupField } from '@monorepo/shared/componentsV2/fields/RadioGroupField';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired as isRequiredValidator } from '@monorepo/shared/utils/validators';
import { JoinType } from 'mapistry-shared';
import React from 'react';
import { StyledJoinTypeDescription } from './styled';

const joinTypeOptions: SelectableOption[] = [
  { label: 'Only include matching rows', value: JoinType.INNER },
  { label: 'Include all rows', value: JoinType.FULL_OUTER },
  {
    label:
      'Include all rows in data source 1, but only matching rows from data source 2',
    value: JoinType.LEFT_OUTER,
  },
];

export function JoinTypePicker() {
  return (
    <>
      <h3>
        Join Rows <span aria-hidden>*</span>
      </h3>
      <StyledJoinTypeDescription>
        {`Choose how you'd like to join the rows from your combined data sources.`}
      </StyledJoinTypeDescription>
      <RadioGroupField
        isLabelHidden
        label="Join Rows"
        name="joinType"
        options={joinTypeOptions}
        required
        validate={isRequiredValidator}
      />
    </>
  );
}
