import LinkIcon from '@material-ui/icons/LaunchRounded';
import { cardBorder } from '@monorepo/shared/styles/card';
import { focusOutline } from '@monorepo/shared/styles/focus';
import LockIcon from '@svg/lock.svg';
import React from 'react';
import styled from 'styled-components';
import { ActionMenu } from '../../menus/ActionMenu';
import { IconButtonLink } from '../../Button/IconButton';
import { Tooltip } from '../../Tooltip';
import { VisuallyHidden } from '../../VisuallyHidden';

const Container = styled.li`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 38rem;
  margin: 0.5rem 0;
  ${cardBorder}
`;

const Outline = styled.div<{
  $isLocked: boolean;
  $hasRightAdornment: boolean;
}>`
  position: relative;
  display: flex;
  flex: 1 0 100%;
  flex-flow: row;
  align-items: center;
  padding: 0.625rem
    ${({ $hasRightAdornment }) => ($hasRightAdornment ? '3rem' : '0.5rem')}
    0.5rem 0.5rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray333};
  ${({ $isLocked, theme }) =>
    $isLocked ? `background-color: ${theme.colors.grayeee}` : ''}
`;

const IconTooltip = styled(Tooltip)`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  width: 2.2rem;

  & svg {
    height: 1rem;
    fill: ${({ theme }) => theme.colors.gray333};
  }
`;

const FieldName = styled.div`
  flex: 1 1 auto;
`;

const StyledIconButtonLink = styled(IconButtonLink)`
  height: 1rem;
  padding: 0;
  margin-left: 0.5rem;
  font-size: 1rem;
`;

const Required = styled.div`
  flex: 0 0 auto;
  padding-left: 1rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray666};
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.grayddd};
`;

const Lock = styled(MenuContainer)`
  & svg {
    height: 1rem;
    fill: ${({ theme }) => theme.colors.gray666};
  }

  &:focus-visible {
    ${focusOutline({ $outlineOffset: '0' })}
  }
`;

const AdditionalInfo = styled.div`
  flex: 1 0 100%;
  padding: 0.625rem 0.5rem 0.5rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray333};
  border-top: 1px solid ${({ theme }) => theme.colors.grayddd};
`;

type FieldTypePreviewProps = {
  actionMenuOptions?: React.ComponentProps<typeof ActionMenu>['options'];
  additionalInfo?: React.ReactElement | string;
  className?: string;
  fieldName: string;
  fieldTypeName?: string;
  icon?: React.ReactElement;
  iconTooltip?: string;
  isLocked?: boolean;
  isRequired?: boolean;
  link?: {
    to: string;
    title: string;
  };
  lockedIconTooltip?: string;
  outerElement?: React.ElementType | keyof JSX.IntrinsicElements;
};

export function FieldTypePreview({
  actionMenuOptions,
  additionalInfo,
  className,
  fieldName,
  fieldTypeName = 'field',
  icon,
  iconTooltip,
  isLocked = false,
  isRequired = false,
  link,
  lockedIconTooltip,
  outerElement = 'li',
}: FieldTypePreviewProps) {
  return (
    <Container className={className} as={outerElement}>
      <Outline
        $isLocked={isLocked}
        $hasRightAdornment={!!actionMenuOptions?.length || isLocked}
      >
        {!!icon && !!iconTooltip && (
          <IconTooltip title={iconTooltip}>
            <div>{icon}</div>
          </IconTooltip>
        )}
        <FieldName>
          {fieldName}
          {link && (
            <Tooltip title={link.title} placement="right">
              <StyledIconButtonLink color="primary" to={link.to}>
                <VisuallyHidden>{link.title}</VisuallyHidden>
                <LinkIcon />
              </StyledIconButtonLink>
            </Tooltip>
          )}
        </FieldName>

        {isRequired && <Required>Required</Required>}
        {!!actionMenuOptions?.length && !isLocked && (
          <MenuContainer>
            <ActionMenu
              altText={`Action menu for ${fieldName} ${fieldTypeName}`}
              options={actionMenuOptions}
            />
          </MenuContainer>
        )}
        {isLocked && (
          <Tooltip
            placement="bottom"
            tabIndex={0}
            title={
              lockedIconTooltip ||
              `The ${fieldName} ${fieldTypeName} cannot be edited.`
            }
          >
            <Lock>
              <LockIcon />
            </Lock>
          </Tooltip>
        )}
      </Outline>
      {!!additionalInfo && <AdditionalInfo>{additionalInfo}</AdditionalInfo>}
    </Container>
  );
}
