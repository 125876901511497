import { ActionMenu } from '@monorepo/shared/componentsV2/menus/ActionMenu';
import { useToast } from '@monorepo/shared/contexts';
import { header2 } from '@monorepo/shared/styles/text';
import {
  FormTemplateFieldGroupResponse,
  FormTemplateResponse,
} from 'mapistry-shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useFormTemplateGroupUpdate } from '../../hooks/useFormTemplateGroupUpdate';
import { EditSectionNameModal } from './EditSectionNameModal/EditSectionNameModal';

type EditableSectionNameProps = {
  activeGroup: FormTemplateFieldGroupResponse;
  formTemplate: FormTemplateResponse;
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  padding: 0.15em 1.5em;
  margin-bottom: 2em;
  background-color: ${({ theme }) => theme.colors.grayeee};
  border-radius: 0.3rem;
`;

const SectionNameDisplay = styled('h2')`
  flex-grow: 1;
  ${header2}
`;

export function EditableSectionName({
  activeGroup,
  formTemplate,
}: EditableSectionNameProps) {
  const [nameModalIsOpen, setNameModalIsOpen] = useState(false);
  const [activeGroupName, setActiveGroupName] = useState('');

  const { organizationId } = useParams();
  const { showUserFriendlyErrorToast, success: showSuccessToast } = useToast();
  const [updateFormTemplateGroup] = useFormTemplateGroupUpdate({
    config: { throwOnError: true },
  });

  useEffect(() => {
    if (activeGroup?.name) setActiveGroupName(activeGroup.name);
  }, [activeGroup, setActiveGroupName]);

  const openEditNameModal = useCallback(() => {
    setNameModalIsOpen(true);
  }, [setNameModalIsOpen]);

  const closeEditNameModal = useCallback(() => {
    setNameModalIsOpen(false);
  }, [setNameModalIsOpen]);

  const nameSubmit = useCallback(
    async (formResponse: { name: string }) => {
      try {
        if (organizationId && activeGroup) {
          await updateFormTemplateGroup({
            organizationId,
            id: formTemplate.id,
            name: formResponse.name,
            groupId: activeGroup.id,
          });
        }
        closeEditNameModal();
        setActiveGroupName(formResponse.name);
        showSuccessToast('Name saved.');
      } catch (err) {
        showUserFriendlyErrorToast(err, `Unable to save name.`, {
          dontAutoHide: true,
        });
      }
    },
    [
      organizationId,
      activeGroup,
      closeEditNameModal,
      setActiveGroupName,
      showSuccessToast,
      updateFormTemplateGroup,
      formTemplate.id,
      showUserFriendlyErrorToast,
    ],
  );

  return (
    <Container>
      <SectionNameDisplay>{activeGroupName}</SectionNameDisplay>
      <ActionMenu
        color="secondary"
        options={[
          {
            displayName: 'Rename',
            onClick: openEditNameModal,
          },
        ]}
      />
      {!!nameModalIsOpen && (
        <EditSectionNameModal
          isOpen={!!nameModalIsOpen}
          onClose={closeEditNameModal}
          onSubmit={nameSubmit}
          name={activeGroupName}
          groups={formTemplate.groups}
        />
      )}
    </Container>
  );
}
