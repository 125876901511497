import { useQueryStepsForm } from '@monorepo/logs/src/contexts/QueryStepsContext';
import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { PotentiallyIncompleteQueryStep } from '@monorepo/logs/src/types';
import { AggregateBySectionHeader } from '@monorepo/shared/componentsV2/aggregation/AggregateBySectionHeader';
import { DEFAULT_AGGREGATION_METHOD } from '@monorepo/shared/componentsV2/aggregation/AggregationDefinitionsFieldArray/consts';
import { GroupBySectionHeader } from '@monorepo/shared/componentsV2/aggregation/GroupBySectionHeader';
import arrayMutators from 'final-form-arrays';
import {
  Aggregation,
  AggregationColumn,
  AggregationMethod,
  AggregationStep,
  GroupByInterval,
  QueryColumn,
  QueryOperationType,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { DeepPartial } from 'utility-types';
import { AggregationDefinitionList } from './AggregationDefinitionList';
import { AggregationGroupByList } from './AggregationGroupByList';
import { AggregationIntervalSelect } from './AggregationIntervalSelect';
import { FormValues } from './types';

const Container = styled.div`
  width: 100%;
`;

/**
 * A COUNT needs to happen over a column that is guaranteed to have data (non-null) for
 * every entry (so that, well, every entry gets counted).
 * Whenever we have data where we have a column that is the unique identifier (most cases!), we know
 * that that column is such a column with data in every row, so we use it.
 * However, if there has already been an aggregation count step before the current one, we no
 * longer necessarily have a uniqueIdentifier column. But in that case we know that we have
 * an aggregation/count column that has data in it (the resulting column of the previous count step),
 * that got saved in the database with a hardcoded standard column name. So in that case, fall back
 * to using that column as the count column.
 */
const getCountColumnName = (availableColumns: QueryColumn[]) => {
  const uniqueIdentifierColumn = availableColumns.find(
    (c) => c.isUniqueIdentifier,
  );

  // in case we are adding a count on top of an aggregration step, count over the
  // result column of that previous aggregation step
  const alreadyExistingCountColumn = 'LOG_ENTRY_COUNT'; // AGGREGATION_ROW_COUNT value on the backend

  const countColumnName =
    uniqueIdentifierColumn?.columnName || alreadyExistingCountColumn;

  return countColumnName;
};

const buildQueryStep = (
  queryStep: PotentiallyIncompleteQueryStep,
  values: FormValues,
  dateColumn: GroupByInterval['columnName'] | undefined,
  availableColumns: QueryColumn[],
): AggregationStep => {
  const countColumnName = getCountColumnName(availableColumns);

  const columns = values.columns.map((ac) => {
    if (ac.method === AggregationMethod.COUNT) {
      return { ...ac, columnName: countColumnName };
    }
    return ac;
  });
  return {
    ...queryStep,
    operationType: QueryOperationType.AGGREGATION,
    operation: {
      columns,
      groupBy: values.groupBy,
      groupByInterval:
        values.interval && dateColumn
          ? { columnName: dateColumn, interval: values.interval }
          : undefined,
    },
  };
};

const buildInitialValues = (
  operation?: Partial<Aggregation>,
): DeepPartial<FormValues> => {
  let columns: Partial<AggregationColumn>[] = operation?.columns || [
    { method: DEFAULT_AGGREGATION_METHOD },
  ];
  if (operation?.columns) {
    columns = columns.map((c) => {
      // Count method has no column in the UI
      if (c.method === AggregationMethod.COUNT) {
        return { ...c, columnName: '' };
      }
      return c;
    });
  }
  return {
    columns,
    interval: operation?.groupByInterval?.interval,
    groupBy: operation?.groupBy || [],
  };
};

export function AggregationQueryStep() {
  const { queryStep, isLastStep, index, dateColumn, availableColumns } =
    useSingleQueryStep();
  const {
    onQueryStepSubmit,
    registerHandleSubmitForStep,
    setQueryStepsArePristine,
  } = useQueryStepsForm();
  const aggregationQueryStep = queryStep as Partial<AggregationStep>;

  const onSubmit = useCallback(
    (values: FormValues) =>
      onQueryStepSubmit(
        buildQueryStep(queryStep, values, dateColumn, availableColumns),
        index,
      ),
    [availableColumns, dateColumn, index, onQueryStepSubmit, queryStep],
  );

  const initialValues = useMemo(
    () => buildInitialValues(aggregationQueryStep.operation),
    [aggregationQueryStep.operation],
  );

  return (
    <Form<FormValues, DeepPartial<FormValues>>
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
    >
      {({ handleSubmit, pristine }) => {
        if (isLastStep) {
          // nothing on an aggregation step can be changed if it's not the last step, so don't even register the handleSubmit function
          registerHandleSubmitForStep(handleSubmit, index);
          setQueryStepsArePristine(pristine);
        }
        return (
          <Container>
            <AggregateBySectionHeader />
            <AggregationIntervalSelect />

            <AggregationDefinitionList />

            <GroupBySectionHeader />
            <AggregationGroupByList />
          </Container>
        );
      }}
    </Form>
  );
}
