import * as Sentry from '@sentry/browser';
import _capitalize from 'lodash.capitalize';
import {
  BaseCreateFieldRequest,
  BaseUpdateFieldRequest,
  FieldResponse,
} from 'mapistry-shared';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useToast } from '../../../contexts/ToastContext';
import { Button } from '../../Button';
import { BaseModal } from '../../modals/BaseModal';

export function getError(values: Partial<FieldResponse>) {
  // any error thrown in this function is unexpected - the UI should've protected against these
  const errMsg = `Tried to save field without all required field request properties`;
  Sentry.captureException(errMsg, { extra: { values } });
  return new Error(errMsg);
}

export function mapFormValuesToBaseRequestDto(
  values: Partial<FieldResponse>,
): BaseCreateFieldRequest | BaseUpdateFieldRequest {
  // these should always be defined by the time the form is getting submitted
  if (!values.type || !values.name) throw getError(values);

  const commonProperties = {
    name: values.name,
    description: values.description,
    isRequired: !!values.isRequired, // always send a defined isRequired
  };
  let baseRequestDto: BaseCreateFieldRequest | BaseUpdateFieldRequest;
  if (values.id) {
    baseRequestDto = { ...commonProperties, id: values.id };
  } else {
    baseRequestDto = { ...commonProperties, type: values.type };
  }

  return baseRequestDto;
}

interface BaseFieldWizardModalProps {
  field?: Partial<FieldResponse>;
  fieldTypeName?: string;
  onClose: () => void;
  onSubmit: (
    values: Partial<FieldResponse>,
    pageNumber: number,
  ) => Promise<void>;
  showBackButton?: boolean;
  startPage?: number;
  wizardPages: React.ReactElement[];
}

interface ActionButtonsParams {
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  isSubmitting: boolean;
}

export function BaseFieldWizardModal({
  field,
  fieldTypeName = 'field',
  onClose,
  onSubmit: onSubmitProp,
  showBackButton,
  startPage = 0,
  wizardPages,
}: BaseFieldWizardModalProps) {
  const [wizardPageNum, setWizardPageNum] = useState(startPage);
  const fieldExists = !!field?.id;

  const isLastPage = wizardPageNum === wizardPages.length - 1;
  const isFirstPage = wizardPageNum === 0;

  const handleNextPage = useCallback(() => {
    setWizardPageNum(wizardPageNum + 1);
  }, [wizardPageNum]);
  const handlePreviousPage = useCallback(() => {
    setWizardPageNum(wizardPageNum - 1);
  }, [wizardPageNum]);

  const { showUserFriendlyErrorToast, success: showSuccess } = useToast();
  const onSubmit = useCallback(
    async (values: Partial<FieldResponse>) => {
      try {
        await onSubmitProp(values, wizardPageNum);
        if (!isLastPage) {
          handleNextPage();
          return;
        }
        showSuccess(
          `${values.name} has been ${fieldExists ? 'edited.' : 'created.'}`,
        );
        onClose();
      } catch (error) {
        showUserFriendlyErrorToast(error, `Unable to save ${values.name}.`);
      }
    },
    [
      onSubmitProp,
      wizardPageNum,
      isLastPage,
      showSuccess,
      fieldExists,
      onClose,
      handleNextPage,
      showUserFriendlyErrorToast,
    ],
  );

  const getButtons = useCallback<
    (params: ActionButtonsParams) => React.ReactElement
  >(
    ({ disabled = false, onClick, isSubmitting }) => {
      const getSaveButtonText = () => {
        if (isSubmitting) return 'Saving...';
        return isLastPage ? 'Save' : 'Next';
      };

      return (
        <>
          {!isFirstPage && showBackButton && (
            <Button color="primary" onClick={handlePreviousPage} type="button">
              Back
            </Button>
          )}
          <Button
            color="primary"
            disabled={disabled}
            onClick={onClick}
            type="submit"
            variant="contained"
          >
            {getSaveButtonText()}
          </Button>
        </>
      );
    },
    [handlePreviousPage, isFirstPage, isLastPage, showBackButton],
  );

  return (
    <Form<FieldResponse>
      initialValues={field}
      onSubmit={onSubmit}
      subscription={{
        invalid: true,
        pristine: true,
        submitting: true,
      }}
    >
      {({ handleSubmit, invalid, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <BaseModal
            buttons={getButtons({
              disabled:
                (isLastPage && pristine) || (pristine && invalid) || submitting,
              isSubmitting: submitting,
              onClick: (e) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleSubmit(e);
              },
            })}
            maxWidth="md"
            onClose={onClose}
            open
            showCloseButton
            title={`Configure ${_capitalize(fieldTypeName)}`}
          >
            {wizardPages[wizardPageNum]}
          </BaseModal>
        </form>
      )}
    </Form>
  );
}
