import MuiTooltip from '@material-ui/core/Tooltip';
import { KeyService } from '@monorepo/shared/KeyService';
import { bodySmall } from '@monorepo/shared/styles/text';
import InfoIcon from '@svg/info.svg';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useOpenClose } from '../hooks/useOpenClose';
import { focusOutline } from '../styles/focus';
import { IconButton } from './Button/IconButton';

const StyledTooltip = styled(MuiTooltip)`
  &:focus-visible {
    ${focusOutline({ $outlineOffset: '-0.125rem' })}
  }

  & + .MuiTooltip-popper .MuiTooltip-tooltip {
    margin: 0;
    background-color: ${({ theme }) => theme.colors.blue};
    ${bodySmall}

    & .MuiTooltip-arrow {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  flex-shrink: 0;
  min-width: 0.75rem;
  height: 1.5rem;
  font-size: 0.75rem;

  & svg {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;

type TooltipProps = Omit<
  React.ComponentProps<typeof MuiTooltip>,
  'children'
> & {
  children?: React.ReactElement;
};

/**
 * The `children` prop must be able to handle receiving a ref so that the tooltip knows where in the DOM it originated
 * (https://github.com/mui/material-ui/issues/15663). Possible solutions:
 *  1. Wrap component with React.forwardRef, passing the ref forward to whichever element is appropriate
 *    https://next--material-ui.netlify.com/guides/composition/#caveat-with-refs
 *  2. If that doesn't work, wrap the component with a plain div as mentioned in
 *    https://github.com/mui-org/material-ui/issues/15663#issuecomment-520778909 (may affect spacing, you can also use a span):
 *      <Tooltip>
 *        <div>
 *          <MyFunctionComponent />
 *        </div>
 *      </Tooltip
 */
export function Tooltip({ children, ...props }: TooltipProps) {
  const [isOpen, open, close] = useOpenClose();

  const closeOnEscape = useCallback(
    (event) => {
      if (KeyService.isEscapeKey(event.keyCode)) {
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    document.addEventListener('keydown', closeOnEscape);

    return () => {
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, [closeOnEscape]);

  return (
    <StyledTooltip
      arrow
      interactive
      onClose={close}
      onOpen={open}
      open={isOpen}
      PopperProps={{
        disablePortal: true,
      }}
      {...props}
    >
      {children || (
        <StyledIconButton>
          <InfoIcon />
        </StyledIconButton>
      )}
    </StyledTooltip>
  );
}
