import { useQueryColumnOptions } from '@monorepo/logs/src/hooks/useQueryColumnOptions';
import { ComboboxField } from '@monorepo/shared/componentsV2/fields/ComboboxField';
import { getNonArchivedSelectableOptions } from '@monorepo/shared/utils/getNonArchivedSelectableOptions';
import { QueryColumn } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useField } from 'react-final-form';

type SelectColumnOptionFieldProps = Omit<
  React.ComponentProps<typeof ComboboxField>,
  'loading' | 'loadingText' | 'options'
> & {
  organizationId: string;
  projectId: string;
  queryOriginId: string;
  column: QueryColumn;
};

export function SelectColumnOptionField({
  column,
  organizationId,
  projectId,
  queryOriginId,
  ...comboboxFieldProps
}: SelectColumnOptionFieldProps) {
  const { columnOptions, isError, isLoading } = useQueryColumnOptions({
    columnOriginId: column.originId,
    config: { useErrorBoundary: false },
    organizationId,
    projectId,
    queryOriginId,
  });
  const { name } = comboboxFieldProps;
  const { meta } = useField<string>(name);
  const options = useMemo(
    () => getNonArchivedSelectableOptions(columnOptions, meta.initial),
    [columnOptions, meta.initial],
  );

  const loadingErrorText = useMemo(() => {
    if (!column.originId) return 'No select options available for this column';
    if (isError) return `Couldn't load column options`;
    return undefined;
  }, [column.originId, isError]);

  return (
    <ComboboxField
      {...comboboxFieldProps}
      autoHighlight
      isLoading={isLoading}
      isLoadingError={isError || !column.originId}
      loadingErrorText={loadingErrorText}
      loadingText="Loading column options..."
      options={options}
    />
  );
}
