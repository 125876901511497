import { InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiInput from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { bodyMedium } from '../styles/text';
import { Button } from './Button';
import { Tooltip } from './Tooltip';

const Input = styled(MuiInput)`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 35rem;
  height: 2rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray333};
  border: 1px solid ${({ theme }) => theme.colors.grayddd};
  border-radius: 4px;

  & svg {
    width: 0.875rem;
    height: 0.875rem;
  }

  &.Mui-error {
    border-color: ${({ theme }) => theme.colors.darkRed};
  }
` as typeof MuiInput;

const Options = styled.fieldset`
  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  padding: 0;
  margin: 0;
  vertical-align: top;
  border: 0;
`;

const StyledInputLabel = styled(InputLabel)`
  ${bodyMedium}
  color: ${({ theme }) => theme.colors.gray333};
`;

const HelperText = styled(FormHelperText)`
  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

const AddButton = styled(Button)`
  max-width: 7rem;
`;

interface ListBuilderProps {
  addItemButtonLabel?: string;
  className?: string;
  error?: boolean;
  errorText?: string;
  helperText?: string;
  isDisabled?: boolean;
  isLabelHidden?: boolean;
  isRequired?: boolean;
  label: string;
  name: string;
  onChange: (value: string[]) => void;
  toolTipText?: string;
  value: string[];
}

export function ListBuilder({
  addItemButtonLabel = 'Add item',
  className,
  error,
  errorText,
  helperText,
  isDisabled = false,
  isLabelHidden = false,
  isRequired = false,
  label,
  name,
  onChange,
  toolTipText,
  value,
}: ListBuilderProps) {
  const [idRandomify] = useState(uuidv4);
  const elementId = `${name}-${idRandomify}`;
  const [list, setList] = useState<string[]>(value || ['']);

  const addItem = () => {
    setList([...list, '']);
  };

  const filterAndSubmit = useCallback(
    (l: string[]) => {
      const newList = l.filter((item) => !!item);
      onChange(newList);
    },
    [onChange],
  );

  const handleChange = useCallback(
    (index) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newList = [...list];
      newList[index] = e.target.value;
      setList(newList);
      filterAndSubmit(newList);
    },
    [filterAndSubmit, list],
  );

  const removeItem = useCallback(
    (index: number) => () => {
      if (list.length === 1) {
        return;
      }
      const newList = list.filter((_, i) => i !== index);
      setList(newList);
      filterAndSubmit(newList);
    },
    [filterAndSubmit, list],
  );

  const tooltip = toolTipText ? <Tooltip title={toolTipText} /> : '';

  return (
    <Options className={className}>
      {isLabelHidden ? (
        <VisuallyHidden>{label}</VisuallyHidden>
      ) : (
        <StyledInputLabel error={error} required={isRequired}>
          {label}
          {tooltip}
        </StyledInputLabel>
      )}
      <MuiList
        id={elementId}
        aria-describedby={helperText ? `${elementId}-helper-text` : undefined}
      >
        {list.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <MuiListItem dense disableGutters key={index}>
            <VisuallyHidden>
              <label htmlFor={`${elementId}-${index}`}>
                {`${label} item ${index + 1}`}
              </label>
            </VisuallyHidden>
            <Input
              disabled={isDisabled}
              disableUnderline
              endAdornment={
                !isDisabled && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={`remove ${label} item ${index + 1}`}
                      onClick={removeItem(index)}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
              error={error}
              id={`${elementId}-${index}`}
              onChange={handleChange(index)}
              value={item}
            />
          </MuiListItem>
        ))}
      </MuiList>
      <HelperText error={error} id={`${elementId}-helper-text`}>
        {errorText || helperText}
      </HelperText>
      {!isDisabled && (
        <AddButton color="primary" onClick={addItem}>
          <AddCircleOutlineIcon />
          {addItemButtonLabel}
        </AddButton>
      )}
    </Options>
  );
}
