import { AggregationDefinitionFieldArray } from '@monorepo/shared/componentsV2/aggregation/AggregationDefinitionsFieldArray/AggregationDefinitionsFieldArray';
import {
  AggregationDefinitionRequest,
  DatasetPropertyType,
  DatasetResponse,
} from 'mapistry-shared';
import React, { useCallback } from 'react';
import { DeepPartial } from 'utility-types';
import { FormValues } from './types';

const propertyTypesAvailableForAggregation = [
  DatasetPropertyType.BOOLEAN,
  DatasetPropertyType.DATE,
  DatasetPropertyType.DATETIME,
  DatasetPropertyType.NUMERIC,
  DatasetPropertyType.TEXT,
  DatasetPropertyType.TIME,
  DatasetPropertyType.USER,
  DatasetPropertyType.UUID,
];

interface AggregationDefinitionsProps {
  stepInputDataset: DatasetResponse;
}

export function AggregationDefinitionList({
  stepInputDataset,
}: AggregationDefinitionsProps) {
  const getAllowedColumnOptions = useCallback(
    (formValues: DeepPartial<FormValues>) => {
      const selectedGroupedPropertyIds = formValues.groupedPropertyIds || [];
      return stepInputDataset.schemaProperties
        .filter(
          (p) =>
            propertyTypesAvailableForAggregation.includes(p.type) &&
            !selectedGroupedPropertyIds.includes(p.id),
        )
        .map((p) => ({
          label: p.fullName,
          value: p.id,
          isNumeric: p.type === DatasetPropertyType.NUMERIC,
        }));
    },
    [stepInputDataset],
  );

  return (
    <AggregationDefinitionFieldArray<
      FormValues,
      'aggregationDefinitions',
      AggregationDefinitionRequest
    >
      childFieldNameForAlias="name"
      childFieldNameForMethod="method"
      childFieldNameForColumn="propertyId"
      fieldName="aggregationDefinitions"
      getAllowedColumnOptions={getAllowedColumnOptions}
    />
  );
}
