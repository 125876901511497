import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { DateRangeFilterButton } from '@monorepo/shared/componentsV2/DateRangeFilterButton';
import { MenuButton } from '@monorepo/shared/componentsV2/menus/MenuButton';
import { Toolbar } from '@monorepo/shared/componentsV2/Toolbar';
import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import React from 'react';
import styled from 'styled-components';
import { LogEntryExportModal } from './LogEntryExportModal';

const StyledToolbar = styled(Toolbar)`
  & .MuiToolbar-root {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

type LogEntryToolbarProps = Omit<
  React.ComponentProps<typeof Toolbar>,
  'rightActions' | 'leftActions'
> & {
  logId: string;
  organizationId: string;
  projectId: string;
  onAddNew: () => void;
  onUpload: () => void;
};

export function LogEntryToolbar({
  logId,
  organizationId,
  projectId,
  onAddNew,
  onUpload,
  ...props
}: LogEntryToolbarProps) {
  const canSubmitDataForProject = useHasProjectUpdatePermissions(projectId);

  const [exportModalIsOpen, handleOpenExportModal, closeExportModal] =
    useOpenClose();

  return (
    <>
      <StyledToolbar
        leftActions={<DateRangeFilterButton />}
        rightActions={
          canSubmitDataForProject ? (
            <ButtonsGroup>
              <Button onClick={handleOpenExportModal}>Export</Button>
              <MenuButton
                label="Add Log data"
                options={[
                  { onClick: onAddNew, displayName: 'Add manually' },
                  { onClick: onUpload, displayName: 'Upload file' },
                ]}
                menuWidthShouldMatchButtonEl
              />
            </ButtonsGroup>
          ) : null
        }
        size="small"
        variant="noDropShadow"
        {...props}
      />
      {
        // reset the modal each time it reopens
        exportModalIsOpen && (
          <LogEntryExportModal
            open={exportModalIsOpen}
            onClose={closeExportModal}
            logId={logId}
            organizationId={organizationId}
            projectId={projectId}
          />
        )
      }
    </>
  );
}
