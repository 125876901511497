import {
  ColumnType,
  isDateLike,
  Operator,
  QueryColumn,
  SingleFilter,
} from 'mapistry-shared';
import React from 'react';
import { BooleanFilterCondition } from './BooleanFilterCondition';
import { DateFilterCondition } from './DateFilterCondition';
import { DateRangeFilterCondition } from './DateRangeFilterCondition';
import { NumericFilterCondition } from './NumericFilterCondition';
import { TextFilterCondition } from './TextFilterCondition';

export function FilterConditionFactory({
  columnType,
  name,
}: {
  columnType: ColumnType | undefined;
  name: string;
}) {
  switch (columnType) {
    case ColumnType.DATETIME:
      return <DateFilterCondition name={name} />;
    case ColumnType.DATE_RANGE:
      return <DateRangeFilterCondition name={name} />;
    case ColumnType.DATE:
      return <DateFilterCondition name={name} />;
    case ColumnType.FOREIGN_ID:
    case ColumnType.TIME:
      throw new Error(`Filter condition not implemented - ${columnType}`);
    case ColumnType.BOOLEAN:
      return <BooleanFilterCondition name={name} />;
    case ColumnType.NUMBER:
      return <NumericFilterCondition name={name} />;
    case ColumnType.TEXT:
      return <TextFilterCondition name={name} />;
    case undefined:
      /* This will be the case when no column has been selected */
      /* put empty divs here to keep the number of columns in the grid consistent */
      return (
        <>
          <div />
          <div />
        </>
      );
    default: {
      const exhaustiveCheck: never = columnType;
      throw new Error(`Invalid column type - ${exhaustiveCheck}`);
    }
  }
}

interface GetFilterConditionForRequestParams {
  columns: QueryColumn[];
  filterConditionFromForm: SingleFilter;
}
export function getFilterConditionForRequest({
  columns: availableColumns,
  filterConditionFromForm,
}: GetFilterConditionForRequestParams) {
  const column = availableColumns.find(
    (c) => c.columnName === filterConditionFromForm.columnName,
  );

  if (!column) {
    throw new Error(`Column not found - ${filterConditionFromForm.columnName}`);
  }

  const updatedFilter = { ...filterConditionFromForm };

  switch (column.columnType) {
    case ColumnType.TIME:
    case ColumnType.FOREIGN_ID:
      throw new Error(
        `Filter condition not implemented - ${column.columnType}`,
      );
    case ColumnType.BOOLEAN: {
      if (filterConditionFromForm.value === 'null') {
        updatedFilter.operator = Operator.EMPTY;
        updatedFilter.value = null;
      } else {
        updatedFilter.operator = Operator.EQUALS;
        updatedFilter.value = filterConditionFromForm.value === 'true';
      }
      return updatedFilter;
    }
    case ColumnType.DATE_RANGE:
    case ColumnType.DATETIME:
    case ColumnType.DATE: {
      updatedFilter.value = isDateLike(filterConditionFromForm.value)
        ? new Date(filterConditionFromForm.value)
        : null;
      return updatedFilter;
    }
    case ColumnType.NUMBER: {
      const valueAsNumber = Number(filterConditionFromForm.value);
      updatedFilter.value = valueAsNumber;
      return updatedFilter;
    }
    case ColumnType.TEXT:
      if (updatedFilter.value == null) {
        // This may be undefined if 'empty' or 'not empty' operators were selected
        updatedFilter.value = null;
      }
      return updatedFilter;
    default: {
      const exhaustiveCheck: never = column.columnType;
      throw new Error(`Invalid column type - ${exhaustiveCheck}`);
    }
  }
}
interface GetFilterConditionFormValuesParams {
  columns: QueryColumn[];
  filterConditionFromQueryStep: SingleFilter;
}
export function getFilterConditionFormValues({
  columns: availableColumns,
  filterConditionFromQueryStep,
}: GetFilterConditionFormValuesParams) {
  const column = availableColumns.find(
    (c) => c.columnName === filterConditionFromQueryStep.columnName,
  );

  if (!column) {
    throw new Error(
      `Column not found - ${filterConditionFromQueryStep.columnName}`,
    );
  }

  const updatedFilter = { ...filterConditionFromQueryStep };

  switch (column.columnType) {
    case ColumnType.TIME:
    case ColumnType.FOREIGN_ID:
      throw new Error(
        `Filter condition not implemented - ${column.columnType}`,
      );
    case ColumnType.BOOLEAN: {
      if (filterConditionFromQueryStep.value == null) {
        updatedFilter.operator = Operator.EMPTY;
        updatedFilter.value = 'null';
      } else {
        updatedFilter.operator = Operator.EQUALS;
        updatedFilter.value = filterConditionFromQueryStep.value
          ? 'true'
          : 'false';
      }
      return updatedFilter;
    }
    case ColumnType.DATE:
    case ColumnType.DATETIME:
    case ColumnType.DATE_RANGE:
    case ColumnType.NUMBER:
    case ColumnType.TEXT:
      return updatedFilter;
    default: {
      const exhaustiveCheck: never = column.columnType;
      throw new Error(`Invalid column type - ${exhaustiveCheck}`);
    }
  }
}
