import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { PopperPlacementType } from '@material-ui/core/Popper';
import { useAnchorElement } from '@monorepo/shared/componentsV2/menus/useAnchorElement';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { bodyMedium, bodyRegular } from '../../styles/text';
import { genericMenuItemStyles, MenuList, PopperContainer } from './styled';

const TitleContainer = styled.p`
  ${bodyMedium};
  padding: 0 1.5rem;
  color: ${({ theme }) => theme.colors.gray333};
  background-color: ${({ theme }) => theme.colors.white};
`;

const MenuItem = styled(MuiMenuItem)<{
  width?: number;
  $hasMenuTitle?: boolean;
  $color: MenuColor;
}>`
  ${bodyRegular}
  ${genericMenuItemStyles}
  width: ${({ width }) => (width ? `${width}px` : '')};
  padding: ${({ $hasMenuTitle }) => $hasMenuTitle && '1.5rem'};
  color: ${({ $color, theme }) =>
    $color === 'secondary' ? theme.colors.gray333 : theme.colors.lightBlue};
  -webkit-text-stroke: 0.5px ${({ theme }) => theme.colors.darkerBlue};

  &:hover,
  &:focus-visible {
    background-color: ${({ $color, theme }) =>
      $color === 'secondary' ? theme.colors.blue04 : theme.colors.lightBlue08};
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  padding-right: 1rem;

  & svg {
    height: 0.8rem;
  }
`;

export type MenuColor = 'primary' | 'secondary';

type MenuOption = {
  displayName: string;
  icon?: ReactNode;
  onClick: React.ComponentProps<typeof MenuItem>['onClick'];
};

type MenuProps = {
  // NOTE! Any onClick handler already set on this element will be overwritten. onClick for this element will open the popper.
  buttonElement: ReactElement;
  color?: MenuColor;
  /** e.g. when rendering inside a dialog */
  disablePopperPortal?: boolean;
  options: MenuOption[];
  menuWidthShouldMatchButtonEl?: boolean;
  menuTitle?: string;
  popperPlacement?: PopperPlacementType;
};

export function Menu({
  buttonElement,
  color = 'primary',
  options,
  menuWidthShouldMatchButtonEl = false,
  menuTitle,
  popperPlacement = 'bottom-end',
  disablePopperPortal = false,
}: MenuProps) {
  const { anchorEl, handleAnchorElClick, handleClose, handleKeyDown } =
    useAnchorElement();

  // add/override click handler on the button element to trigger opening the popper
  const buttonElementWithClickHandler = useMemo(
    () => React.cloneElement(buttonElement, { onClick: handleAnchorElClick }),
    [buttonElement, handleAnchorElClick],
  );

  const anchorElWidth = anchorEl?.offsetWidth || 0;

  return (
    <>
      {buttonElementWithClickHandler}

      <PopperContainer
        disablePortal={disablePopperPortal}
        anchorEl={anchorEl}
        open={!!anchorEl}
        placement={popperPlacement}
      >
        {menuTitle && (
          <>
            <TitleContainer>{menuTitle}</TitleContainer>
            <Divider />
          </>
        )}
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList autoFocusItem={!!anchorEl} variant="menu">
            {options.map((opt) => (
              <MenuItem
                key={opt.displayName}
                onClick={(event) => {
                  opt.onClick && opt.onClick(event);
                  handleClose();
                }}
                onKeyDown={handleKeyDown}
                $color={color}
                $hasMenuTitle={!!menuTitle}
                width={menuWidthShouldMatchButtonEl ? anchorElWidth : undefined}
              >
                {opt.icon && (
                  <IconContainer aria-hidden>{opt.icon}</IconContainer>
                )}
                {opt.displayName}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </PopperContainer>
    </>
  );
}
