import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { InputLabel } from '@monorepo/shared/componentsV2/Input';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useDatasets } from '@monorepo/shared/hooks/workflows/useDatasets';
import { isRequired } from '@monorepo/shared/utils/validators';
import React from 'react';
import { useField } from 'react-final-form';
import { useParams } from 'react-router-dom-v5-compat';
import { DatasetPicker } from '../../shared/DatasetPicker/DatasetPicker';
import { EmptyDatasetList } from '../../shared/DatasetPicker/EmptyDatasetList';
import {
  InfoContainer,
  StyledHelperText,
} from '../../shared/DatasetPicker/styled';
import { DatasetErrorFallback } from './DatasetErrorFallback';

import { SelectedDataset } from './SelectedDataset';

function DatasetsDisplay() {
  const { organizationId, workflowId } = useParams();
  const workflowExists = !!workflowId;

  const { datasets, isFetching } = useDatasets({
    config: { enabled: !workflowExists },
    organizationId,
  });

  if (workflowExists) {
    return <SelectedDataset />;
  }

  if (isFetching) {
    return (
      <InfoContainer>
        <Loading size="medium" />
      </InfoContainer>
    );
  }

  if (!datasets || !datasets.length) {
    return <EmptyDatasetList includeWorkflows={false} />;
  }

  return (
    <DatasetPicker
      datasets={datasets}
      fieldName="inputDatasetId"
      isLabelHidden
      label="Data source"
    />
  );
}

export const DatasetSection = () => {
  const { meta } = useField<string>('inputDatasetId', { validate: isRequired });
  const hasError = (!!meta.error && (meta.touched || meta.modified)) || false;

  return (
    <>
      <InputLabel required error={hasError}>
        Data source
      </InputLabel>
      <StyledHelperText>
        Choose the data source that you&apos;d like to build your workflow from.
        You can join additional data sources within the workflow in the next
        steps. This cannot be changed after the Workflow is created.
      </StyledHelperText>
      <ErrorBoundary Fallback={DatasetErrorFallback}>
        <DatasetsDisplay />
      </ErrorBoundary>
    </>
  );
};
