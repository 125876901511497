import ArrowBack from '@material-ui/icons/ArrowBack';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import { Loading } from '@monorepo/shared/components/Loading';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { MenuButton } from '@monorepo/shared/componentsV2/menus/MenuButton';
import { Search } from '@monorepo/shared/componentsV2/Search';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useResourceType } from '@monorepo/shared/hooks/resources/useResourceType';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';

const SearchContainer = styled.div`
  max-width: 14rem;
`;

const ButtonContainer = styled.div`
  min-width: 7rem;

  & > button {
    width: 100%;
  }
`;

type ViewResourcesHeaderProps = {
  onSearchChange?: React.ComponentProps<typeof Search>['onChange'];
  onSettingsClick: React.ComponentProps<typeof Button>['onClick'];
  onAddNew: React.MouseEventHandler;
  onAddNewFromFile: () => void;
};

export function ViewResourcesHeader(props: ViewResourcesHeaderProps) {
  const { onSearchChange, onSettingsClick, onAddNew, onAddNewFromFile } = props;

  const { organizationId, resourceTypeId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

  const { resourceType, isLoading } = useResourceType({
    organizationId,
    resourceTypeId,
  });
  const resourceTypeName = resourceType?.name || '';

  const navigate = useNavigate();

  return isLoading ? (
    <Loading />
  ) : (
    <Header
      variant="noDropShadow"
      title={resourceTypeName}
      leftActions={
        <IconButton onClick={() => navigate('../../../../..')}>
          <ArrowBack />
          <VisuallyHidden>Back</VisuallyHidden>
        </IconButton>
      }
      rightActions={
        <ButtonsGroup>
          {onSearchChange && (
            <SearchContainer>
              <Search placeholder="Search by name" onChange={onSearchChange} />
            </SearchContainer>
          )}
          <ButtonContainer>
            <Button
              variant="outlined"
              color="primary"
              onClick={onSettingsClick}
            >
              <SettingsOutlined />
              Settings
            </Button>
          </ButtonContainer>
          {canEditOrgSettings && (
            <ButtonContainer>
              <MenuButton
                label="Add Resources"
                options={[
                  { onClick: onAddNew, displayName: 'Add manually' },
                  { onClick: onAddNewFromFile, displayName: 'Upload file' },
                ]}
                menuWidthShouldMatchButtonEl
              />
            </ButtonContainer>
          )}
        </ButtonsGroup>
      }
    />
  );
}
