import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import { Operator } from 'mapistry-shared';
import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { ValueField } from './ValueField';

const operatorOptions: SelectableOption<Operator>[] = [
  { label: 'Is greater than', value: Operator.GREATER_THAN },
  { label: 'Is less than', value: Operator.LESS_THAN },
  {
    label: 'Is greater than or equal to',
    value: Operator.GREATER_THAN_OR_EQUAL,
  },
  { label: 'Is less than or equal to', value: Operator.LESS_THAN_OR_EQUAL },
  { label: 'Equals', value: Operator.EQUALS },
  { label: 'Does not equal', value: Operator.NOT_EQUALS },
  { label: 'Is empty', value: Operator.EMPTY },
  { label: 'Is not empty', value: Operator.NOT_EMPTY },
];

export function NumericFilterCondition({ name }: { name: string }) {
  const { change, getFieldState } = useForm();
  const operatorFieldState = getFieldState(`${name}.operator`);

  useEffect(() => {
    if (operatorFieldState && operatorFieldState.value == null) {
      // Filter condition is new, default the operator to 'greater than'
      change(`${name}.operator`, Operator.GREATER_THAN);
    }
  }, [change, name, operatorFieldState]);

  return (
    <>
      <SelectField
        isLabelHidden
        label="Operator"
        name={`${name}.operator`}
        options={operatorOptions}
        placeholder="Operator"
        required
        validate={isRequired}
      />
      <ValueField fieldType="number" filterConditionName={name} />
    </>
  );
}
