import styled from 'styled-components';
import { bodySmall, header4 } from '../../styles/text';

export const SectionHeader = styled.h3`
  ${header4}
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const Subtext = styled.div`
  ${bodySmall}
  padding-bottom: 1rem;
  color: ${({ theme }) => theme.colors.gray666};
`;
