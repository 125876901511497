import { Api } from '@monorepo/shared/apiClient';
import {
  LogCacheEvents,
  logCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceTypeCacheRegister';
import {
  WorkflowCacheEvents,
  workflowCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/workflowCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

type KeyParams = Api.FetchDatasetParams;

const KEY_START = 'dataset' as const;
export const createKey = ({ organizationId, datasetId }: KeyParams) =>
  [KEY_START, organizationId, datasetId] as const;

type Fetcher = Api.DataHookQueryFn<typeof createKey, typeof Api.fetchDataset>;

const fetcher: Fetcher = (_, organizationId, datasetId) =>
  Api.fetchDataset({ organizationId, datasetId });

type UseDatasetParams = Partial<Api.FetchDatasetParams> & {
  config?: QueryConfig<Api.FetchDatasetResponse, Api.ErrorResponse>;
};

export function useDataset({
  config: inputConfig,
  organizationId,
  datasetId,
}: UseDatasetParams) {
  const isEnabled = !!organizationId && !!datasetId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ organizationId, datasetId }) : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return { ...queryInfo, dataset: data };
}

type InvalidateCacheParams = Pick<KeyParams, 'organizationId'>;

export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([KEY_START, keyParams.organizationId]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useDataset',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

resourceTypeCacheRegister(
  [ResourceTypeCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);

logCacheRegister([LogCacheEvents.UPDATE], cacheRegisterInvalidator);

workflowCacheRegister([WorkflowCacheEvents.UPDATE], cacheRegisterInvalidator);
