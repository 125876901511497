import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiInput from '@material-ui/core/InputBase';
import MuiInputLabel from '@material-ui/core/InputLabel';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { bodyMedium } from '../styles/text';
import { Tooltip } from './Tooltip';

const InputContainer = styled(MuiInput)`
  position: relative;
  display: flex;
  align-items: ${({ multiline }) => (multiline ? 'flex-start' : 'center')};
  min-height: ${({ multiline }) => (multiline ? '4.5rem' : '2rem')};
  padding: ${({ multiline }) => (multiline ? '0.5rem' : '0')} 0.5rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray333};
  border: 1px solid ${({ theme }) => theme.colors.grayddd};
  border-radius: 4px;

  &:not(.Mui-disabled) {
    &:hover,
    &.Mui-focused {
      outline: ${({ theme }) => `1px solid ${theme.colors.gray333}`};
    }
  }

  &.Mui-error {
    border-color: ${({ theme }) => theme.colors.darkRed};
  }
`;

const InputLabelContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const InputLabel = styled(MuiInputLabel)`
  ${bodyMedium}
  position: static;
  margin-bottom: 0.2rem;
  color: ${({ theme }) => theme.colors.gray333};
  transform: none;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray333};
  }

  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

const HelperText = styled(FormHelperText)`
  margin-top: 0;
  margin-bottom: 0.2rem;

  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

const ErrorText = styled(FormHelperText)`
  color: ${({ theme }) => theme.colors.darkRed};

  /* for errors like 'Required', Mui specificity styling would
  override our error color, and not pass a11y contrast ratio */
  &.MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

type InputProps = React.ComponentProps<typeof MuiInput> & {
  errorText?: string;
  isLabelHidden?: boolean;
  helperText?: string;
  label: string;
  name: string;
  dontSaveSpaceForErrorText?: boolean;
  tooltipText?: string;
};

export function Input({
  className,
  dontSaveSpaceForErrorText,
  error,
  errorText,
  fullWidth,
  isLabelHidden = false,
  helperText,
  label,
  name,
  required,
  tooltipText,
  ...props
}: InputProps): React.ReactElement {
  const [idRandomify] = useState(uuidv4);

  const inputId = `${name || label}-${idRandomify}`;
  const helperTextId = `${inputId}-helper-text`;
  const errorTextId = `${inputId}-error-text`;

  const labelComponent = (
    <InputLabel required={required} htmlFor={inputId}>
      {label}
    </InputLabel>
  );
  return (
    <FormControl className={className} error={error} fullWidth={fullWidth}>
      {isLabelHidden ? (
        <VisuallyHidden>{labelComponent}</VisuallyHidden>
      ) : (
        <InputLabelContainer>
          {labelComponent}
          {tooltipText && <Tooltip title={tooltipText} placement="right" />}
        </InputLabelContainer>
      )}

      {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}
      <InputContainer
        id={inputId}
        {...props}
        required={required}
        aria-describedby={`${errorTextId} ${helperText ? helperTextId : ''}`}
      />
      {/* Showing space char if no error in order to reserve space,  */}
      {/* so form elements won't jiggle when an input goes from valid to invalid and vice versa */}
      <ErrorText id={errorTextId}>
        {errorText || (dontSaveSpaceForErrorText ? '' : ' ')}
      </ErrorText>
    </FormControl>
  );
}
