import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import { Operator } from 'mapistry-shared';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-final-form';
import { DateValueField } from './DateValueField';

const allOperatorOptions: SelectableOption<Operator>[] = [
  { label: 'Equals', value: Operator.EQUALS },
  { label: 'Is empty', value: Operator.EMPTY },
  { label: 'Is not empty', value: Operator.NOT_EMPTY },
  { label: 'Is not', value: Operator.NOT_EQUALS },
  { label: 'Is before', value: Operator.BEFORE },
  { label: 'Is equal or before', value: Operator.EQUAL_OR_BEFORE },
  { label: 'Is after', value: Operator.AFTER },
  { label: 'Is equal or after', value: Operator.EQUAL_OR_AFTER },
];
const allOperators = allOperatorOptions.map((o) => o.value);

export function DateFilterCondition({
  name,
  supportedOperators = allOperators,
}: {
  name: string;
  supportedOperators?: Operator[];
}) {
  const { change, batch, getFieldState } = useForm();
  const operatorFieldState = getFieldState(`${name}.operator`);
  const isCheckEmptiness =
    operatorFieldState?.value === Operator.EMPTY ||
    operatorFieldState?.value === Operator.NOT_EMPTY;
  useEffect(() => {
    if (!operatorFieldState) return;
    const isUnsupportedOperator = !supportedOperators.includes(
      operatorFieldState.value,
    );
    if (operatorFieldState.value == null || isUnsupportedOperator) {
      // Filter condition is new, default to 'is before today'
      batch(() => {
        change(`${name}.operator`, Operator.BEFORE);
        change(`${name}.value`, new Date());
      });
    }
  }, [batch, change, name, operatorFieldState, supportedOperators]);
  useEffect(() => {
    if (isCheckEmptiness) {
      // Empty / Not empty get null comparison value
      change(`${name}.value`, null);
    }
  }, [change, name, isCheckEmptiness]);

  const operatorOptions: SelectableOption<Operator>[] = useMemo(
    () =>
      supportedOperators
        .map((operator) =>
          allOperatorOptions.find((opt) => opt.value === operator),
        )
        .filter((o): o is SelectableOption<Operator> => o !== undefined),
    [supportedOperators],
  );

  return (
    <>
      <SelectField
        isLabelHidden
        label="Operator"
        name={`${name}.operator`}
        options={operatorOptions}
        placeholder="Operator"
        required
        validate={isRequired}
      />
      <DateValueField filterConditionName={name} />
    </>
  );
}
