import TrashIcon from '@svg/trash.svg';
import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';
import { DeepPartial } from 'utility-types';
import { isRequired } from '../../../utils/validators';
import { IconButton } from '../../Button/IconButton';
import { SelectField } from '../../fields/SelectField';
import { SelectOption } from '../../Select';
import { VisuallyHidden } from '../../VisuallyHidden';
import { AnyFieldValues } from '../types';

const Container = styled.div`
  display: flex;
`;

const StyledSelectField = styled(SelectField)`
  width: 25rem;
`;

const DeleteButton = styled(IconButton)`
  min-width: 2rem;
  margin-bottom: 1.3rem; /* lines this up vertically with the input elements to its left */
  font-size: 0.75rem;
`;

interface AggregationGroupBySelectProps<FormValues extends AnyFieldValues> {
  canEdit: boolean;
  formFieldIndex: number;
  formFieldName: string;
  getAllowedGroupByColumnOptions: (
    formValues: DeepPartial<FormValues>,
    fieldIndex?: number,
  ) => SelectOption[];
  onDelete?: () => void;
}

export function AggregationGroupBySelect<FormValues extends AnyFieldValues>({
  canEdit,
  formFieldIndex,
  formFieldName,
  getAllowedGroupByColumnOptions,
  onDelete,
}: AggregationGroupBySelectProps<FormValues>) {
  const { values: currentFormValues } = useFormState<DeepPartial<FormValues>>();

  const groupByOptions = useMemo(
    () =>
      getAllowedGroupByColumnOptions(currentFormValues, formFieldIndex).sort(
        (op1, op2) => op1.label.localeCompare(op2.label),
      ),
    [currentFormValues, formFieldIndex, getAllowedGroupByColumnOptions],
  );

  return (
    <Container>
      <StyledSelectField
        disabled={!canEdit}
        isLabelHidden
        label={`Group by column ${formFieldIndex + 1}`}
        name={formFieldName}
        noOptionsText="There are no columns available to group by"
        options={groupByOptions}
        placeholder="Column to group by"
        required
        validate={isRequired}
      />
      {onDelete && (
        <DeleteButton onClick={onDelete}>
          <TrashIcon />
          <VisuallyHidden>Delete group by</VisuallyHidden>
        </DeleteButton>
      )}
    </Container>
  );
}
