import { AggregationGroupByFieldArray } from '@monorepo/shared/componentsV2/aggregation/AggregationGroupByFieldArray/AggregationGroupByFieldArray';
import React, { useCallback } from 'react';
import { DeepPartial } from 'utility-types';
import { useSingleQueryStep } from '../../../contexts/SingleQueryStepContext';
import { getAllowedGroupByColumns } from './getAllowedGroupByColumns';
import { FormValues } from './types';

export function AggregationGroupByList() {
  const { availableColumns, columnSets, isLastStep, limitColumnSets } =
    useSingleQueryStep();

  const getAllowedGroupByColumnOptions = useCallback(
    (formValues: DeepPartial<FormValues>, fieldIndex?: number) =>
      getAllowedGroupByColumns(
        availableColumns,
        columnSets,
        limitColumnSets,
        formValues,
        fieldIndex,
      ).map((c) => ({
        label: c.columnLabel,
        value: c.columnName,
      })),
    [availableColumns, columnSets, limitColumnSets],
  );

  return (
    <AggregationGroupByFieldArray<FormValues>
      canEdit={isLastStep}
      fieldName="groupBy"
      getAllowedGroupByColumnOptions={getAllowedGroupByColumnOptions}
    />
  );
}
