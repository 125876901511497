import { useCallback, useState } from 'react';
import { KeyService } from '../../KeyService';

export const useAnchorElement = () => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleAnchorElClick = useCallback(
    (event) => setAnchorEl(anchorEl ? null : event.currentTarget),
    [anchorEl, setAnchorEl],
  );

  const handleClose = useCallback(() => {
    // restore logical keyboard state by returning focus to the element that opened the menu
    anchorEl?.focus();
    setAnchorEl(null);
  }, [anchorEl, setAnchorEl]);

  const handleKeyDown = useCallback(
    (event) => {
      // any interaction within the menu is with up/down arrow keys
      if (
        KeyService.isEscapeKey(event.keyCode) ||
        KeyService.isTabKey(event.keyCode)
      ) {
        handleClose();
      }
    },
    [handleClose],
  );

  return {
    anchorEl,
    handleAnchorElClick,
    handleClose,
    handleKeyDown,
  };
};
