import FormControl from '@material-ui/core/FormControl';
import { DatePickerField } from '@monorepo/shared/componentsV2/fields/DatePickerField';
import { isRequired } from '@monorepo/shared/utils/validators';
import { Operator, SingleFilter } from 'mapistry-shared';
import React from 'react';
import { useField } from 'react-final-form';

export function DateValueField({
  filterConditionName,
}: {
  filterConditionName: string;
}) {
  const {
    input: { value: filterOperator },
  } = useField<SingleFilter['operator']>(`${filterConditionName}.operator`);
  const shouldDisplay =
    filterOperator &&
    filterOperator !== Operator.EMPTY &&
    filterOperator !== Operator.NOT_EMPTY;

  if (!shouldDisplay)
    return (
      <>
        {/* put an empty div here to keep the number of columns in the grid consistent */}
        <div />
      </>
    );

  return (
    <FormControl fullWidth>
      <DatePickerField
        label="Date"
        isLabelHidden
        fullWidth
        name={`${filterConditionName}.value`}
        placeholder="Date"
        required
        validate={isRequired}
      />
    </FormControl>
  );
}
