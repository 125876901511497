import { isLimitThresholdColumn } from '@monorepo/logs/src/components/Views/ViewResultsTable/resultsMapper';
import { ColumnSet, ColumnType, QueryColumn } from 'mapistry-shared';
import { LimitColumnSet } from 'mapistry-shared/dist/dto/edp/QueryRequest';
import { DeepPartial } from 'utility-types';
import { FormValues } from './types';

const COLUMN_TYPES_ALLOWED_TO_GROUP_BY = [
  ColumnType.BOOLEAN,
  ColumnType.FOREIGN_ID,
  ColumnType.TEXT,
];

// If an id column doesn't have a columnSet, there are no dependant columns to group by and
// to show in View results table. If we allow grouping by such columns, a user won't know how the data was
// grouped-by by just looking at resulting rows.
// For example, we want to filter out columns that originated from resource field dataset properties,
// because for dataset Views, if a user wants to group by resource itself they should do it by record property.
function isIdOrHiddenColumnWithoutColumnSet(
  column: QueryColumn,
  columnSets: ColumnSet[],
) {
  if (column.columnType !== ColumnType.FOREIGN_ID && !column.isHidden)
    return false;
  return !columnSets.some((cs) => cs.parentColumn === column.columnName);
}

export function getAllowedGroupByColumns(
  availableColumns: QueryColumn[],
  columnSets: ColumnSet[],
  limitColumnSets: LimitColumnSet[],
  currentFormValues: DeepPartial<FormValues>,
  fieldIndex?: number,
): QueryColumn[] {
  const alreadySelectedGroupBys = (currentFormValues?.groupBy || []).filter(
    (columnName): columnName is string => !!columnName,
  );
  // We don't allow grouping by number columns, but a user might count distinct values for a different column type.
  // It doesn't make sense to aggregate and group by the same column, so removing it from selectable options.
  const alreadySelectedColumnsForAggregation = (
    currentFormValues?.columns || []
  ).map(({ columnName }) => columnName);

  const disallowedColumnOptions = alreadySelectedGroupBys.reduce(
    (
      excludedColumnNames: string[],
      selectedGroupByColumnName: string,
      idx: number,
    ) => {
      // if we're looking at selected column for the dropdown we're getting the options for, don't remove column options
      //  that that selection disqualifies
      // Examples:
      //  - if a certain group by column is set to column "Type", the dropdown for that should still show "Type" as an option
      //  - if it is set to a resource, the dropdown should allow switching from that resource to one of its dependent fields
      //  - if it is set to a resource property, the dropdown should allow switching to the resource itself (UNLESS another
      //    selected group by column also is set to one of that resource's properties)
      if (idx === fieldIndex) {
        return excludedColumnNames;
      }

      // not allowed to group by the same column multiple times
      const toExclude: string[] = [selectedGroupByColumnName];

      // for each column set, can only choose the parent column OR a subset of the dependent columns
      //  (if you are grouping by resource, for example, it is redundant to group by a specific property on that resource)
      columnSets.forEach((cs) => {
        if (cs.parentColumn === selectedGroupByColumnName) {
          toExclude.push(...cs.dependentColumns);
          toExclude.push(...(cs.dependentParentColumns || []));
        }
        if (cs.dependentColumns.includes(selectedGroupByColumnName)) {
          toExclude.push(cs.parentColumn);
        }
        if (
          (cs.dependentParentColumns || []).includes(selectedGroupByColumnName)
        ) {
          toExclude.push(cs.parentColumn);
        }
      });

      return [...excludedColumnNames, ...toExclude];
    },
    [],
  );

  const groupByOptions = availableColumns
    .filter((c) => COLUMN_TYPES_ALLOWED_TO_GROUP_BY.includes(c.columnType))
    .filter(
      (c) =>
        !disallowedColumnOptions.includes(c.columnName) &&
        !isIdOrHiddenColumnWithoutColumnSet(c, columnSets) &&
        !isLimitThresholdColumn(c.columnName, limitColumnSets) &&
        !alreadySelectedColumnsForAggregation.includes(c.columnName),
    );
  return groupByOptions;
}
