import MuiMenuList from '@material-ui/core/MenuList';
import MuiPopper from '@material-ui/core/Popper';
import styled, { css } from 'styled-components';

export const genericMenuButtonStyles = css`
  padding-right: 0.2rem;
`;

export const menuButtonArrowDown = css`
  margin-right: 0;
  margin-left: 0.5rem;
  font-size: 1.5rem;
`;

export const PopperContainer = styled(MuiPopper)`
  z-index: ${({ theme }) => theme.constants.menuZIndex};
  max-height: 20rem;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  box-shadow: 0 4px 13px ${({ theme }) => theme.colors.blue20};
`;

export const MenuList = styled(MuiMenuList)`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  padding: 0;
`;

export const genericMenuItemStyles = css`
  justify-content: left;
  height: 2.125rem;
  border-radius: 0;
`;
